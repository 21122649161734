const userDetail = {
    id: "",
    webName: "",
    name: "",
    email: "",
    password: "",
    userRole: "",
    mobileNumber: "",
};

export default userDetail;
