import React, { Component } from "react";
import "../style.css";
import adminDetails from "../../adminDetails";
import { errorLog } from "../../common/userlogs/userlogs";
export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutData: {},
      isLoaded: false,
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      let text = adminDetails.about.content
        ? adminDetails.about.content.length > 500
          ? adminDetails.about.content.slice(0, 500) + " ..."
          : adminDetails.about.content
        : "";

      document.getElementById("aboutContent").innerHTML = text.replace(
        /\n/g,
        "<br/>"
      );
      this.setState(
        {
          aboutData: adminDetails.about,
        },
        () =>
          this.setState({
            isLoaded: true,
          })
      );
    }, 1000);

  }catch(err){
    errorLog("componentDidMount", err, "", "", "");
  }
}

  render() {
    return (
      <div>
        <div id="aboutus" style={{ paddingTop: "120px" }}>
          {/* <!--PAGE TITLE ******************************************************************************************--> */}
          <section id="page-title">
            <div className="container">
              <div className="ts-title">
                <h2 className="text-dynamicColor">
                  {this.state.aboutData
                    ? this.state.aboutData.title
                    : "About Us"}
                </h2>
              </div>
              {/* <!--end ts-title--> */}
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!--DESCRIPTION *****************************************************************************************--> */}
          <section id="about-us-description">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <p className="mb-5" id="aboutContent">
                    {/* {this.state.aboutData.content
                      ? this.state.aboutData.content.length > 500
                        ? this.state.aboutData.content.slice(0, 500) + " ..."
                        : this.state.aboutData.content
                      : "Nothing to Display"} */}
                  </p>
                  {/* {this.state.aboutData.content.length > 30 && ( */}
                  <a
                    onClick={() =>
                      (window.location.href = `/${localStorage.getItem(
                        "webName"
                      )}/aboutusdetail`)
                    }
                    style={{ background: "00f", marginTop: "20px" }}
                    className="btn btn-primary"
                  >
                    Read More
                  </a>
                  {/* )} */}
                </div>
                {/* <!--end col-md-8--> */}
                <div className="col-md-4"></div>
                {/* <!--end col-md-4--> */}
              </div>
              {/* <!--end row--> */}
              <hr className="my-5" />
            </div>
            {/* <!--end container--> */}
          </section>
        </div>
      </div>
    );
  }
}
