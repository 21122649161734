/* eslint-disable */
import React, { Component } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Mainpage from "./components/Mainpage";
import PropertyDetails from "./components/Properties/PropertyDetails";
import Aboutusdetail from "./components/AboutUs/Aboutusdetail";
// import AgentDetail from "./components/AboutUs/AgentDetail";
// import Login from "./components/Login/Login";
import axios from "axios";
import baseUrls from "./common/utils/base-urls";
import adminDetails from "./adminDetails";
import SearchProperties from "./components/Properties/SearchProperties";
import Warning from "./components/webPage/Warning";

var r = document.querySelector(":root");
export default class RouteContainer extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.myFunctionSet("#36cca5");
    let data = window.location.href.split("/");
    let tmpData = window.location.href
      .replace(data[0] + "/" + data[1] + "/" + data[2] + "/", "")
      .split("/");
    let webName = tmpData[0];
    if (
      localStorage.getItem("webName") != webName ||
      adminDetails.email == ""
    ) {
      localStorage.setItem("webName", webName);
      axios
        .get(`${baseUrls.serverUrl}users/getAakarUserDetails/` + webName)
        .then((res) => {
          axios
            .post(`${baseUrls.serverUrl}property/getProperty`, {
              uid: res.data[0].uid,
            })
            .then((propertyResponse) => {
              adminDetails.parentUid = res.data[0].uid;
              adminDetails.about = res.data[0].aakarConfiguration.aboutUs;
              adminDetails.property = propertyResponse.data;
              adminDetails.email = res.data[0].email;
              adminDetails.mobileNumber = res.data[0].mobileNumber;
              adminDetails.logo = res.data[0].photoURL;
              adminDetails.webName = res.data[0].webName;
              adminDetails.bannerData = res.data[0].aakarConfiguration.banner;
              adminDetails.socialMedia = res.data[0].aakarConfiguration
                .socialMedia
                ? res.data[0].aakarConfiguration.socialMedia
                : {
                    facebook: "",
                    pinterest: "",
                    twitter: "",
                    youtube: "",
                  };
              adminDetails.calendly = res.data[0].aakarConfiguration.calendly;
              adminDetails.whatsappNumber =
                res.data[0].aakarConfiguration.whatsappNumber;
              adminDetails.teamMembers = res.data[0].aakarConfiguration
                .teamMembers
                ? res.data[0].aakarConfiguration.teamMembers
                : [];
              adminDetails.themeColor = res.data[0].aakarConfiguration
                .themeColor
                ? res.data[0].aakarConfiguration.themeColor
                : { backgroundColor: "#9d0b0b", fontColor: "#339ba3" };
              adminDetails.footer = res.data[0].aakarConfiguration.footerContent
                ? res.data[0].aakarConfiguration.footerContent
                : "Footer Content Not available";
              adminDetails.getInTouchContent = res.data[0].aakarConfiguration
                .getInTouchContent
                ? res.data[0].aakarConfiguration.getInTouchContent
                : "Footer Content Not available";
              adminDetails.address = res.data[0].address
                ? res.data[0].address
                : {
                    address1: "12, Millind park soc.,",
                    address2:
                      "Nr. Green valley appt., Chattrapati Shivaji Marg, Adajan",
                    city: "Not Applicable / NA",
                    country: "India",
                    state: "Gujarat",
                  };

              adminDetails.privacyPolicyUrl = res.data[0].aakarConfiguration
                .privacyPolicyUrl
                ? res.data[0].aakarConfiguration.privacyPolicyUrl
                : "";
              // adminDetails.filterProperty = propertyResponse.data;
            })
            .catch((err) => {
              console.error("err", err);
            });
        })
        .catch((error) => {
          console.error("error=>", error);
        });
    }
  }

  myFunctionSet = (color) => {
    r.style.setProperty("--themecolor", color);
  };

  redirectUrl = () => {
    window.location.href = "https://aakar.melzo.com";
  };

  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch location={this.props.location}>
            <Route exact path="/" component={this.redirectUrl}></Route>
            <Route
              exact
              path="/:webName/aboutusdetail"
              component={Aboutusdetail}
            />
            <Route
              exact
              path="/:webName/filterproperty"
              component={SearchProperties}
            />
            <Route
              exact
              path="/:webName/:propertyId"
              component={PropertyDetails}
            />
            <Route exact path="/:webName" component={Mainpage} />
            <Route exact path="/warning" component={Warning} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
