import React, { Component } from 'react'
import Footer from '../Footer/Footer'
// import Header from '../header/Header'
import '../style.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { errorLog } from '../../common/userlogs/userlogs';

export default class AgentDetail extends Component {
    Property(){
        try{
        window.location.href = "/propertydetail";
    }catch(err){
        errorLog("proparty", err, "", "", "");
    }
    }
    render() {
        return (
            <div>
                {/* <Header header={this.props.header}></Header> */}
                {/* <!--PAGE TITLE
            =========================================================================================================--> */}
                <section id="page-title" style={{ marginTop: '150px' }}>
                    <div className="container">

                        <div className="ts-title">
                            <h1>Agent Detail</h1>
                        </div>

                    </div>
                </section>
                {/* <!--AGENT INFO
            =========================================================================================================--> */}
                <section id="agent-info">
                    <div className="container">

                        {/* <!--Box--> */}
                        <div className="ts-box">

                            {/* <!--Ribbon--> */}
                            <div className="ts-ribbon">
                                <i className="fa fa-thumbs-up"></i>
                            </div>

                            {/* <!--Row--> */}
                            <div className="row">

                                {/* <!--Brand--> */}
                                <div className="col-md-4 ts-center__both">
                                    <div className="ts-circle__xxl ts-shadow__md aboutimg1" ></div>
                                </div>

                                {/* <!--Description--> */}
                                <div className="col-md-8">

                                    <div className="py-4">

                                        {/* <!--Title--> */}
                                        <div className="ts-title mb-2">
                                            <h2 className="mb-1">Jane Harwood</h2>
                                            <h5>
                                                <i className="fa fa-map-marker mr-2"></i>
                                        London
                                    </h5>
                                        </div>

                                        {/* <!--Row--> */}
                                        <div className="row">

                                            <div className="col-md-7">
                                                <p>
                                                Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. 
                                        </p>
                                            </div>

                                            <div className="col-md-5 ts-opacity__50">

                                                {/* <!--Phone--> */}
                                                <figure className="mb-1">
                                                    <i className="fa fa-phone-square mr-2"></i>
                                            +1 602-862-1673
                                        </figure>

                                                {/* <!--Mail--> */}
                                                <figure className="mb-1">
                                                    <i className="fa fa-envelope mr-2"></i>
                                                    <a href="#">jane.harwood@example.com</a>
                                                </figure>

                                                {/* <!--Skype--> */}
                                                <figure className="mb-0">
                                                    <i className="fab fa-skype mr-2"></i>
                                            jane.harwood
                                        </figure>

                                            </div>

                                        </div>
                                        {/* <!--end row--> */}
                                    </div>
                                    {/* <!--end p-4--> */}

                                    <div className="ts-bg-light p-3 ts-border-radius__md d-block d-sm-flex ts-center__vertical justify-content-between ts-xs-text-center">
                                        <a href="#" className="btn btn-outline-secondary btn-sm d-block d-sm-inline-block mb-2 mb-sm-0">All
                                    properties (23)</a>
                                        <small className="ts-opacity__50">Member since: 12.05.2013</small>
                                    </div>

                                </div>
                                {/* <!--end col-md-8--> */}
                            </div>
                            {/* <!--end row--> */}
                        </div>
                        {/* <!--end ts-box--> */}

                    </div>
                    {/* <!--end container--> */}
                </section>
                {/* <!--end #agent-info--> */}
                {/* <!--ITEMS LISTING & SEARCH
            =========================================================================================================--> */}
                <section id="items-listing-and-search">
                    <div className="container">
                        <div className="row flex-wrap-reverse">

                            {/* <!--LEFT SIDE
                        =============================================================================================--> */}
                            <div className="col-md-8">
                                <h3 className="mb-4 pb-4">My Properties</h3>

                                {/* <!--DISPLAY CONTROL
                            =========================================================================================--> */}
                                <section id="display-control-agents" style={{marginBottom:'2rem'}}>
                                    <div className="clearfix">

                                        {/* <!--Display selector on the left--> */}
                                        {/* <div className="float-left">
                                            <a href="#" className="btn btn-outline-secondary px-3 mr-2 mb-2 ts-btn-border-muted">
                                                <i className="fa fa-th-large"></i>
                                            </a>
                                            <a href="#" className="btn btn-outline-secondary active px-3 mb-2 ts-btn-border-muted">
                                                <i className="fa fa-th-list"></i>
                                            </a>
                                        </div> */}

                                        {/* <!--Display selector on the right--> */}
                                        <div className="float-none float-sm-right pl-2 ts-center__vertical">
                                            <label for="sorting" className="mb-0 mr-2 text-nowrap">Sort by:</label>
                                            <select className="custom-select bg-transparent" id="sorting" name="sorting">
                                                <option value="">Default</option>
                                                <option value="1">Price lowest first</option>
                                                <option value="2">Price highest first</option>
                                                <option value="3">Distance</option>
                                            </select>
                                        </div>

                                    </div>
                                    {/* <!--end container--> */}
                                </section>

                                {/* <!--ITEMS LISTING
                            =================================================================================--> */}
                                <section id="items-listing">
                                    {/* <!--Item--> */}
                                    <div className="card ts-item ts-item__list ts-card">

                                        {/* <!--Ribbon--> */}
                                        <div className="ts-ribbon">
                                            <i className="fa fa-thumbs-up"></i>
                                        </div>

                                        {/* <!--Card Image--> */}
                                        <a href="onClick={this.Property}" className="card-img listingimg1"></a>

                                        {/* <!--Card Body--> */}
                                        <div className="card-body">

                                            <figure className="ts-item__info">
                                                <h4>Big Luxury Apartment</h4>
                                                <aside>
                                                    <i className="fa fa-map-marker mr-2"></i>
                                            1350 Arbutus Drive
                                        </aside>
                                            </figure>

                                            <div className="ts-item__info-badge">
                                                $350,000
                                    </div>

                                            <div className="ts-description-lists">
                                                <dl>
                                                    <dt>Area</dt>
                                                    <dd>325m<sup>2</sup></dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bedrooms</dt>
                                                    <dd>2</dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bathrooms</dt>
                                                    <dd>1</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        {/* <!--Card Footer--> */}
                                        <a href="onClick={this.Property}" className="card-footer">
                                            <span className="ts-btn-arrow">Detail</span>
                                        </a>

                                    </div>

                                    {/* <!--Item--> */}
                                    <div className="card ts-item ts-item__list ts-card">

                                        {/* <!--Ribbon--> */}
                                        <div className="ts-ribbon-corner">
                                            <span>Updated</span>
                                        </div>

                                        {/* <!--Card Image--> */}
                                        <a href="onClick={this.Property}" className="card-img ts-item__image listingimg2" ></a>

                                        {/* <!--Card Body--> */}
                                        <div className="card-body ts-item__body">

                                            <figure className="ts-item__info">
                                                <h4>Cozy Design Studio</h4>
                                                <aside>
                                                    <i className="fa fa-map-marker mr-2"></i>
                                            4831 Worthington Drive
                                        </aside>
                                            </figure>

                                            <div className="ts-item__info-badge">$125,000</div>

                                            <div className="ts-description-lists">
                                                <dl>
                                                    <dt>Area</dt>
                                                    <dd>325m<sup>2</sup></dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bedrooms</dt>
                                                    <dd>2</dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bathrooms</dt>
                                                    <dd>1</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        {/* <!--Card Footer--> */}
                                        <a href="onClick={this.Property}" className="card-footer ts-item__footer">
                                            <span className="ts-btn-arrow">Detail</span>
                                        </a>

                                    </div>

                                    {/* <!--Item--> */}
                                    <div className="card ts-item ts-item__list ts-card">

                                        {/* <!--Card Image--> */}
                                        <a href="onClick={this.Property}" className="card-img ts-item__image listingimg3" ></a>

                                        {/* <!--Card Body--> */}
                                        <div className="card-body ts-item__body">

                                            <figure className="ts-item__info">
                                                <h4>Family Villa</h4>
                                                <aside>
                                                    <i className="fa fa-map-marker mr-2"></i>
                                            4127 Winding Way
                                        </aside>
                                            </figure>

                                            <div className="ts-item__info-badge">$45,900</div>

                                            <div className="ts-description-lists">
                                                <dl>
                                                    <dt>Area</dt>
                                                    <dd>325m<sup>2</sup></dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bedrooms</dt>
                                                    <dd>2</dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bathrooms</dt>
                                                    <dd>1</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        {/* <!--Card Footer--> */}
                                        <a href="onClick={this.Property}" className="card-footer ts-item__footer">
                                            <span className="ts-btn-arrow">Detail</span>
                                        </a>

                                    </div>

                                    {/* <!--Item--> */}
                                    <div className="card ts-item ts-item__list ts-card">

                                        {/* <!--Card Image--> */}
                                        <a href="onClick={this.Property}" className="card-img listingimg4" ></a>

                                        {/* <!--Card Body--> */}
                                        <div className="card-body">

                                            <figure className="ts-item__info">
                                                <h4>Traditional Restaurant</h4>
                                                <aside>
                                                    <i className="fa fa-map-marker mr-2"></i>
                                            1350 Arbutus Drive
                                        </aside>
                                            </figure>

                                            <div className="ts-item__info-badge">$860,000</div>

                                            <div className="ts-description-lists">
                                                <dl>
                                                    <dt>Area</dt>
                                                    <dd>325m<sup>2</sup></dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bedrooms</dt>
                                                    <dd>2</dd>
                                                </dl>
                                                <dl>
                                                    <dt>Bathrooms</dt>
                                                    <dd>1</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        {/* <!--Card Footer--> */}
                                        <a href="onClick={this.Property}" className="card-footer">
                                            <span className="ts-btn-arrow">Detail</span>
                                        </a>

                                    </div>
                                </section>

                                {/* <!--PAGINATION
                            =================================================================================--> */}
                                <section id="pagination">
                                    <div className="container">

                                        {/* <!--Pagination--> */}
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination ts-center__horizontal">
                                                <li className="page-item active">
                                                    <a className="page-link" href="#">1</a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">2</a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">3</a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link ts-btn-arrow" href="#">Next</a>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </section>

                                <hr className="my-5" />
                                <section id="contact-form">
                                    <h2>Contact Agent</h2>

                                    {/* <!--Form--> */}
                                    <form id="form-contact" method="post" className="clearfix ts-form ts-form-email" data-php-path="assets/php/email.php">

                                        {/* <!--Row--> */}
                                        <div className="row">

                                            {/* <!--Name--> */}
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="form-contact-name">Name *</label>
                                                    <input type="text" className="form-control" id="form-contact-name" name="name" placeholder="Your Name" required />
                                                </div>
                                            </div>

                                            {/* <!--Email--> */}
                                            <div className="col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="form-contact-email">Email *</label>
                                                    <input type="email" className="form-control" id="form-contact-email" name="email" placeholder="Your Email" required />
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--end row --> */}

                                        {/* <!--Subject--> */}
                                        <div className="form-group">
                                            <label for="form-contact-email">Subject*</label>
                                            <input type="text" className="form-control" id="form-contact-subject" name="subject" placeholder="Subject" required />
                                        </div>

                                        {/* <!--Message--> */}
                                        <div className="form-group">
                                            <label for="form-contact-message">Message *</label>
                                            <textarea className="form-control" id="form-contact-message" rows="5" name="message" placeholder="Your Message" required></textarea>
                                        </div>

                                        {/* <!--Submit button--> */}
                                        <div className="form-group clearfix">
                                            <button type="submit" className="btn btn-primary float-right" id="form-contact-submit">
                                                Send a Message
                                    </button>
                                        </div>

                                        <div className="form-contact-status"></div>

                                    </form>
                                    {/* <!--end form-contact --> */}

                                </section>

                            </div>
                            {/* <!--end col-md-8 / left side --> */}
                            {/* <!--RIGHT SIDE (SIDEBAR)
                        =============================================================================================--> */}
                            <div className="col-md-4 navbar-expand-md">

                                <button className="btn bg-white mb-4 w-100 d-block d-md-none" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="float-left">
                                        <i className="fa fa-search mr-2"></i>
                                    Search
                                </span>
                                    <span className="float-right">
                                        <i className="fa fa-plus small ts-opacity__30"></i>
                                    </span>
                                </button>

                                <aside id="sidebar" className="ts-sidebar collapse navbar-collapse">

                                    {/* <!--SEARCH FORM
                                =====================================================================================--> */}
                                    <section id="sidebar-search-form" style={{ marginBottom: '2rem' }}>

                                        <h3>Search Form</h3>

                                        <form id="form-search" className="ts-form">

                                            {/* <!--Keyword--> */}
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="keyword" name="keyword" placeholder="Address, City or ZIP" />
                                            </div>

                                            {/* <!--Type--> */}
                                            <div className="form-group">
                                                <select className="custom-select" id="type" name="type">
                                                    <option value="">Type</option>
                                                    <option value="1">Apartment</option>
                                                    <option value="2">Villa</option>
                                                    <option value="3">Land</option>
                                                    <option value="4">Garage</option>
                                                </select>
                                            </div>

                                            {/* <!--Status--> */}
                                            <div className="form-group">
                                                <select className="custom-select" id="status" name="status">
                                                    <option value="">Status</option>
                                                    <option value="1">Rent</option>
                                                    <option value="2">Sale</option>
                                                </select>
                                            </div>

                                            {/* <!--Row - Min price & Max price--> */}
                                            <div className="row">

                                                {/* <!--Min Price--> */}
                                                <div className="col-sm-6">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control border-right-0" id="min-price" name="min_price" placeholder="Min Price" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-left-0">$</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!--Max Price--> */}
                                                <div className="col-sm-6">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control border-right-0" id="max-price" name="max_price" placeholder="Max Price" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-left-0">$</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* <!--Submit button--> */}
                                            <div className="form-group my-2">
                                                <button type="submit" className="btn btn-primary w-100" id="search-btn">Search
                                        </button>
                                            </div>

                                            {/* <!--More Options Button--> */}
                                            <a href="#more-options-collapse" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="more-options-collapse">
                                                <i className="fa fa-plus-circle ts-text-color-primary mr-2"></i>
                                        More Options
                                    </a>

                                            {/* <!--Hidden Form--> */}
                                            <div className="collapse" id="more-options-collapse">

                                                {/* <!--Padding--> */}
                                                <div className="py-4">

                                                    {/* <!--Row--> */}
                                                    <div className="form-row">

                                                        {/* <!--Bedrooms--> */}
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label for="bedrooms">Bedrooms</label>
                                                                <input type="number" className="form-control" id="bedrooms" name="bedrooms" min="0" />
                                                            </div>
                                                        </div>

                                                        {/* <!--Bathrooms--> */}
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label for="bathrooms">Bathrooms</label>
                                                                <input type="number" className="form-control" id="bathrooms" name="bathrooms" min="0" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* <!--end row--> */}

                                                    {/* <!--Checkboxes--> */}
                                                    <div id="features-checkboxes" className="w-100">
                                                        <h6 className="mb-3">Features</h6>

                                                        <div className="ts-column-count-2">

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_1" name="features[]" value="ch_1" />
                                                                <label className="custom-control-label" for="ch_1">Air
                                                            conditioning</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_2" name="features[]" value="ch_2" />
                                                                <label className="custom-control-label" for="ch_2">Bedding</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_3" name="features[]" value="ch_3" />
                                                                <label className="custom-control-label" for="ch_3">Heating</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_4" name="features[]" value="ch_4" />
                                                                <label className="custom-control-label" for="ch_4">Internet</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_5" name="features[]" value="ch_5" />
                                                                <label className="custom-control-label" for="ch_5">Microwave</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_6" name="features[]" value="ch_6" />
                                                                <label className="custom-control-label" for="ch_6">Smoking
                                                            allowed</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_7" name="features[]" value="ch_7" />
                                                                <label className="custom-control-label" for="ch_7">Terrace</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_8" name="features[]" value="ch_8" />
                                                                <label className="custom-control-label" for="ch_8">Balcony</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_9" name="features[]" value="ch_9" />
                                                                <label className="custom-control-label" for="ch_9">Iron</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_10" name="features[]" value="ch_10" />
                                                                <label className="custom-control-label" for="ch_10">Hi-Fi</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_11" name="features[]" value="ch_11" />
                                                                <label className="custom-control-label" for="ch_11">Beach</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="ch_12" name="features[]" value="ch_12" />
                                                                <label className="custom-control-label" for="ch_12">Parking</label>
                                                            </div>

                                                        </div>
                                                        {/* <!--end ts-column-count-3--> */}

                                                    </div>
                                                    {/* <!--end #features-checkboxes--> */}

                                                </div>
                                                {/* <!--end Padding--> */}
                                            </div>
                                            {/* <!--end more-options-collapse--> */}

                                        </form>
                                        {/* <!--end #form-search--> */}
                                    </section>
                                    {/* <!--end #sidebar-search-form--> */}

                                    {/* <!--MAP RESULTS
                                =====================================================================================--> */}
                                    <section id="map-results" style={{ marginBottom: '2rem' }}>
                                        <h3>Map Results</h3>

                                        <div id="ts-map-simple" className="ts-sidebar-map"
                                            data-ts-map-leaflet-provider="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png"
                                            data-ts-map-zoom="12"
                                            data-ts-map-center-latitude="40.702411"
                                            data-ts-map-center-longitude="-73.556842"
                                            data-ts-map-scroll-wheel="1"
                                            data-ts-map-controls="0"></div>

                                    </section>

                                    {/* <!--FEATURED PROPERTIES
                                =====================================================================================--> */}
                                    <section id="featured-properties">
                                        <h3 className="text-dynamicColor">Featured Properties</h3>

                                        {/* <div className="owl-carousel" data-owl-dots="1"> */}
                                            <OwlCarousel items="1" autoplay={true}>

                                                {/* <!--Slide--> */}
                                                <div className="slide">
                                                    <div className="card ts-item ts-card ts-item__lg ts-shadow__none">
                                                        <a href="#" className="card-img listingimg1">
                                                            <figure className="ts-item__info">
                                                                <h4>Big Luxury Apartment</h4>
                                                                <aside>
                                                                    <i className="fa fa-map-marker mr-2"></i>
                                                        1350 Arbutus Drive
                                                    </aside>
                                                            </figure>
                                                            <div className="ts-item__info-badge">$350,000</div>
                                                        </a>
                                                    </div>
                                                </div>

                                                {/* <!--Slide--> */}
                                                <div className="slide">
                                                    <div className="card ts-item ts-card ts-item__lg ts-shadow__none">
                                                        <a href="#" className="card-img listingimg2" >
                                                            <figure className="ts-item__info">
                                                                <h4>Cozy Design Studio</h4>
                                                                <aside>
                                                                    <i className="fa fa-map-marker mr-2"></i>
                                                        4831 Worthington Drive
                                                    </aside>
                                                            </figure>
                                                            <div className="ts-item__info-badge">$125,000</div>
                                                        </a>
                                                    </div>
                                                </div>

                                                {/* <!--Slide--> */}
                                                <div className="slide">
                                                    <div className="card ts-item ts-card ts-item__lg ts-shadow__none">
                                                        <a href="#" className="card-img listingimg3">
                                                            <figure className="ts-item__info">
                                                                <h4>Family Villa</h4>
                                                                <aside>
                                                                    <i className="fa fa-map-marker mr-2"></i>
                                                        4831 Worthington Drive
                                                    </aside>
                                                            </figure>
                                                            <div className="ts-item__info-badge">$45,900</div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </OwlCarousel>

                                        {/* </div> */}
                                    </section>


                                </aside>
                                {/* <!--end #sidebar--> */}
                            </div>
                            {/* <!--end Right Side / col-md-4--> */}

                        </div>
                    </div>
                </section>

                <Footer></Footer>
            </div>
        )
    }
}
