import React, { Component } from "react";
import "./home.css";
import "font-awesome/css/font-awesome.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cabinsicon from "../img/svg/icon-cabins-w.svg";
import apartmenticon from "../img/svg/icon-apartment-w.svg";
import houseicon from "../img/svg/icon-house-w.svg";
import Officesicon from "../img/svg/icon-offices-w.svg";
import garagesicon from "../img/svg/icon-garages-w.svg";
import landicon from "../img/svg/icon-land-w.svg";
import img1 from "../img/aakar/bg-woman-mobile.jpg";
import img2 from "../img/aakar/bg-bedroom.jpg";
import img3 from "../img/aakar/bg-apartment-tv.jpg";
import arrowicon from "../img/arrow.svg";
import baseUrls from "../base-urls";
import adminDetails from "../../adminDetails";
import { Slider, Typography } from "@material-ui/core";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { errorLog } from "../../common/userlogs/userlogs";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      bannerData: {},
      isLoaded: false,
      price: [1, 100],
      isMoreOptionClicked: false,
      address: "",
      minPrice: 1,
      maxPrice: 100,
      type: "",
      priceUnit: "Lacs",
      status: "",
      room: "",
      bedroom: "",
      bathroom: "",
      garage: "",
      apartmentCount: 0,
      bunglowCount: 0,
      rowHouseCount: 0,
      shopCount: 0,
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      let apartmentCount = adminDetails.property.filter(
        (property) => property.type == "Apartment"
      );
      let bunglowCount = adminDetails.property.filter(
        (property) => property.type == "Bunglow"
      );
      let rowHouseCount = adminDetails.property.filter(
        (property) => property.type == "Row-House"
      );
      let shopCount = adminDetails.property.filter(
        (property) => property.type == "Shop"
      );
      this.setState(
        {
          uid: adminDetails.parentUid,
          bannerData: adminDetails.bannerData,
          apartmentCount: apartmentCount.length,
          bunglowCount: bunglowCount.length,
          rowHouseCount: rowHouseCount.length,
          shopCount: shopCount.length,
        },
        () =>
          this.setState({
            isLoaded: true,
          })
      );
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err ,"" , "" , "" , "")
  }
}

  handleFilter = (e) => {
    try{
    this.setState({
      [e.target.name]: e.target.value,
    });
  }catch(err){
    errorLog("handleFilter", err ,"" , "" , "" , "")
  }
  };

  handleSearchProperty = () => {
    try{
    console.log("all state", this.state);
    const {
      address,
      status,
      type,
      features,
      garage,
      bathroom,
      bedroom,
      room,
      uid,
      maxPrice,
      minPrice,
      priceUnit,
    } = this.state;

    let maximumPrice =
      priceUnit == "Lacs"
        ? maxPrice * 100000
        : priceUnit == "Crores"
        ? maxPrice * 10000000
        : priceUnit == "Thousands"
        ? maxPrice * 1000
        : maxPrice * 10;
    let minimumPrice =
      priceUnit == "Lacs"
        ? minPrice * 100000
        : priceUnit == "Crores"
        ? minPrice * 10000000
        : priceUnit == "Thousands"
        ? minPrice * 1000
        : minPrice * 10;

    const searchObj = {
      uid: uid,
      address: address,
      minPrice: minimumPrice,
      maxPrice: maximumPrice,
      type: type,
      currentStatus: status,
      roomCount: room,
      bedroomCount: bedroom,
      bathroomCount: bathroom,
      garageCount: garage,
      features: features,
    };
    axios
      .post(baseUrls.serverUrl + `property/search`, searchObj)
      .then((res) => {
        console.log("search Property", res);
        new Promise((response, reject) => {
          adminDetails.filterProperty = res.data;
          response("done");
        }).then((res) => {
          // window.location.href =
          //   "/" + localStorage.getItem("webName") + "/filterproperty";
          this.props.history.push(
            `/${localStorage.getItem("webName")}/filterproperty`
          );
        });

        // this.props.propertyCallBack();
      })
      .catch((error) => {
        console.log("error", error);
      });
    }catch(err){
      errorLog("handleSearchProperty", err, "", "", "")
    }
  };

  render() {
    return (
      <div id="home">
        {/* <!-- HERO SLIDER
    =================================================================================================================--> */}
        <section
          id="ts-hero"
          style={{ paddingTop: "114.219px" }}
          className="ts-separate-bg-element text-center mb-0 ts-center__both "
          data-bg-color="#000"
          data-bg-image-opacity=".5"
        >
          <div className="ts-full-screen ts-center__both">
            {/* <!--Container--> */}
            <div className="container py-2 py-sm-5">
              {/* <!--Title--> */}
              <h1 className="mb-2 text-white">
                {this.state.bannerData
                  ? this.state.bannerData.title
                  : "Banner Heading"}
              </h1>

              {/* <!--Subtitle--> */}
              <h4 className="ts-opacity__50 text-dynamicColor">
                {this.state.bannerData
                  ? this.state.bannerData.tagline
                  : "Banner Tagline"}
              </h4>

              {/* <!--Form--> */}
              <div className="ts-form p-3 my-5 ts-border-radius__md ts-borderless-inputs searchcolor">
                {/* <!--More options button--> */}
                <a
                  href="#more-options-collapse"
                  className="ts-form-advanced-search ts-circle collapsed"
                  onClick={() =>
                    this.setState({
                      isMoreOptionClicked: !this.state.isMoreOptionClicked,
                    })
                  }
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="more-options-collapse"
                >
                  <i className="fa fa-plus ts-visible-on-collapsed"></i>
                  <i className="fa fa-minus ts-visible-on-uncollapsed"></i>
                </a>
                <div className="position-absolute ts-bottom__0 ts-push-down__100 pl-4 ml-1 pt-3 ts-z-index__-1">
                  <img src={arrowicon} alt="" className="ts-opacity__50" />
                  <small className="text-white pl-1 ts-push-down__50 d-inline-block">
                    {this.state.isMoreOptionClicked
                      ? "Show Less"
                      : "More Option"}
                  </small>
                </div>
                {/* <!--Row--> */}
                <div className="row no-gutters">
                  {/* <!--Keyword--> */}
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group my-2">
                      <input
                        type="text"
                        className="form-control"
                        id="keyword"
                        name="address"
                        value={this.state.address}
                        onChange={(e) => this.handleFilter(e)}
                        placeholder="Address, City or ZIP"
                      />
                    </div>
                  </div>

                  {/* <!--Type--> */}
                  <div className="col-sm-6 col-md-3">
                    <select
                      className="custom-select my-2 border-left"
                      id="type"
                      name="type"
                      value={this.state.type}
                      onChange={(e) => this.handleFilter(e)}
                    >
                      <option value="None">Type</option>
                      <option value="Apartment">Apartment</option>
                      <option value="Bunglow">Bunglow</option>
                      <option value="Row House">Row House</option>
                      <option value="Shop">Shop</option>
                    </select>
                  </div>

                  {/* <!--Status--> */}
                  <div className="col-sm-6 col-md-3">
                    <select
                      className="custom-select my-2 border-left"
                      id="status"
                      name="status"
                      value={this.state.status}
                      onChange={(e) => this.handleFilter(e)}
                    >
                      <option value="">Status</option>
                      <option value="Rent">Rent</option>
                      <option value="Sell">Sell</option>
                      <option value="Lease">Lease</option>
                    </select>
                  </div>

                  {/* <!--Price and Submit button--> */}
                  <div className="col-sm-6 col-md-3">
                    <div className="row no-gutters">
                      <div className="col-sm-6"></div>
                      {/* <!--Max Price--> */}
                      {/* <div className="col-sm-6">
                        <select
                          className="custom-select my-2 border-left"
                          id="price"
                          name="status"
                        >
                          <option value="">Max Price</option>
                          <option value={2000000}>&#8377; 20 Lacs</option>
                          <option value={4000000}>&#8377; 40 Lacs</option>
                          <option value={7000000}>&#8377; 70 Lacs</option>
                          <option value={15000000}>&#8377; 1.50 Cr</option>
                        </select>
                      </div> */}

                      {/* <!--Submit button--> */}
                      <div className="col-sm-6">
                        <div className="form-group my-2">
                          <button
                            type="submit"
                            onClick={() => this.handleSearchProperty()}
                            className="btn btn-primary w-100"
                            id="search-btn"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--end row--> */}

                {/* <!--Hidden Form--> */}
                <div className="collapse text-left" id="more-options-collapse">
                  {/* <!--Padding--> */}
                  <div className="py-4">
                    {/* <!--Row--> */}
                    <div className="form-row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            onClick={() => console.log("in lacs")}
                            for="range-slider"
                          >
                            Property Price Range ( in {this.state.priceUnit} )
                          </label>
                          <br></br>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <span
                              type="submit"
                              className={
                                this.state.priceUnit == "Thousands"
                                  ? "btn btn-primary text-dynamicColor"
                                  : "btn btn-primary"
                              }
                              id="search-btn"
                              onClick={() =>
                                this.setState({
                                  priceUnit: "Thousands",
                                })
                              }
                              style={{ margin: "auto 10px" }}
                            >
                              In Thousands
                            </span>
                            <span
                              type="submit"
                              onClick={() =>
                                this.setState({
                                  priceUnit: "Lacs",
                                })
                              }
                              style={{ margin: "auto 10px" }}
                              className={
                                this.state.priceUnit == "Lacs"
                                  ? "btn btn-primary text-dynamicColor"
                                  : "btn btn-primary"
                              }
                              id="search-btn"
                            >
                              In Lacs
                            </span>
                            <span
                              type="submit"
                              onClick={() =>
                                this.setState({
                                  priceUnit: "Crores",
                                })
                              }
                              style={{ margin: "auto 10px" }}
                              className={
                                this.state.priceUnit == "Crores"
                                  ? "btn btn-primary text-dynamicColor"
                                  : "btn btn-primary "
                              }
                              id="search-btn"
                            >
                              In Crores
                            </span>
                          </div>
                          <br></br>
                          <Slider
                            value={this.state.price}
                            onChange={(e, newPrice) =>
                              this.setState({
                                price: newPrice,
                                maxPrice: newPrice[1],
                                minPrice: newPrice[0],
                              })
                            }
                            valueLabelDisplay="on"
                            aria-labelledby="range-slider"
                            getAriaValueText={valuetext}
                            className="dynamicIconColor"
                          />
                        </div>
                      </div>
                      {/* <!--Bedrooms--> */}
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="bedrooms">Bedrooms</label>
                          <input
                            type="number"
                            className="form-control"
                            id="bedrooms"
                            name="bedroom"
                            value={this.state.bedroom}
                            onChange={(e) => this.handleFilter(e)}
                            min="0"
                          />
                        </div>
                      </div>

                      {/* <!--Bathrooms--> */}
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="bathrooms">Bathrooms</label>
                          <input
                            type="number"
                            className="form-control"
                            id="bathrooms"
                            name="bathroom"
                            value={this.state.bathroom}
                            onChange={(e) => this.handleFilter(e)}
                            min="0"
                          />
                        </div>
                      </div>

                      {/* <!--Garages--> */}
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="garages">Garages</label>
                          <input
                            type="number"
                            className="form-control"
                            id="garages"
                            name="garage"
                            value={this.state.garage}
                            onChange={(e) => this.handleFilter(e)}
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--end row--> */}

                    {/* <!--Checkboxes--> */}
                    {/* <div id="features-checkboxes" className="w-100">
                      <label for="garages">Features</label>

                      <div className="ts-column-count-3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_1"
                            name="features[]"
                            value="ch_1"
                          />
                          <label className="custom-control-label" for="ch_1">
                            Air conditioning
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_2"
                            name="features[]"
                            value="ch_2"
                          />
                          <label className="custom-control-label" for="ch_2">
                            Bedding
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_3"
                            name="features[]"
                            value="ch_3"
                          />
                          <label className="custom-control-label" for="ch_3">
                            Heating
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_4"
                            name="features[]"
                            value="ch_4"
                          />
                          <label className="custom-control-label" for="ch_4">
                            Internet
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_5"
                            name="features[]"
                            value="ch_5"
                          />
                          <label className="custom-control-label" for="ch_5">
                            Microwave
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_6"
                            name="features[]"
                            value="ch_6"
                          />
                          <label className="custom-control-label" for="ch_6">
                            Smoking allowed
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_7"
                            name="features[]"
                            value="ch_7"
                          />
                          <label className="custom-control-label" for="ch_7">
                            Terrace
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_8"
                            name="features[]"
                            value="ch_8"
                          />
                          <label className="custom-control-label" for="ch_8">
                            Balcony
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_9"
                            name="features[]"
                            value="ch_9"
                          />
                          <label className="custom-control-label" for="ch_9">
                            Iron
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_10"
                            name="features[]"
                            value="ch_10"
                          />
                          <label className="custom-control-label" for="ch_10">
                            Hi-Fi
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_11"
                            name="features[]"
                            value="ch_11"
                          />
                          <label className="custom-control-label" for="ch_11">
                            Beach
                          </label>
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ch_12"
                            name="features[]"
                            value="ch_12"
                          />
                          <label className="custom-control-label" for="ch_12">
                            Parking
                          </label>
                        </div>
                      </div>
                    </div> */}
                    {/* <!--end ts-column-count-3--> */}

                    {/* <!--end #features-checkboxes--> */}
                  </div>
                  {/* <!--end Padding--> */}
                </div>
                {/* <!--end more-options-collapse--> */}
              </div>

              <a
                href="#featured-properties"
                onClick={() => {
                  setTimeout(() => {
                    let productEl = document.getElementById(
                      "featured-properties"
                    );
                    if (productEl) {
                      productEl.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }, 500);
                }}
                className="ts-scroll btn btn-outline-light btn-sm mt-5 d-none d-md-inline-block"
              >
                Show More Properties
              </a>
            </div>
            {/* <!--end container--> */}
            <div className="ts-background ts-hero-slider ts-bg-black">
              <div className="ts-background-image ts-opacity__30">
                {this.state.bannerData.images && (
                  <OwlCarousel
                    items="1"
                    autoplay={true}
                    dots={false}
                    nav={false}
                    loop
                  >
                    {/* <div className="owl-carousel" data-owl-loop="1" data-owl-fadeout="1"> */}
                    {this.state.bannerData.images.map((imageUrls,index) => {
                      return (
                        <div className="ts-slide" key={index}>
                          <img src={baseUrls.images + imageUrls}></img>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* <!--end ts-hero--> */}
        {/* <!-- CATEGORY SELECT
        =============================================================================================================--> */}
        <section
          id="category-select"
          className="ts-icons-select ts-icons-select__dark"
        >
          {/* <!--Cabins--> */}
          {/* <a href="">
            <aside>17</aside>
            <img src={Cabinsicon} alt="" />
            <figure>
              <h6>Cabins</h6>
              <small>Explore</small>
            </figure>
          </a> */}

          {/* <!--Apartments--> */}
          <div
            style={{ display: "flex", width: "100%" }}
            onClick={() => {
              this.setState(
                {
                  type: "Apartment",
                },
                () => this.handleSearchProperty()
              );
            }}
          >
            <a href="#ts-hero">
              <aside>
                {this.state.apartmentCount && this.state.apartmentCount}
              </aside>
              <img src={apartmenticon} alt="" />
              <figure>
                <h6>Apartments</h6>
                <small>Explore</small>
              </figure>
            </a>
          </div>

          {/* <!--Houses--> */}
          <div
            style={{ display: "flex", width: "100%" }}
            onClick={() => {
              this.setState(
                {
                  type: "Bunglow",
                },
                () => this.handleSearchProperty()
              );
            }}
          >
            <a href="#ts-hero">
              <aside>
                {this.state.bunglowCount && this.state.bunglowCount}
              </aside>
              <img src={houseicon} alt="" />
              <figure>
                <h6>Bunglow</h6>
                <small>Explore</small>
              </figure>
            </a>
          </div>

          {/* <!--Offices--> */}
          <div
            style={{ display: "flex", width: "100%" }}
            onClick={() => {
              this.setState(
                {
                  type: "Shop",
                },
                () => this.handleSearchProperty()
              );
            }}
          >
            <a href="#ts-hero">
              <aside>{this.state.shopCount && this.state.shopCount}</aside>
              <img src={Officesicon} alt="" />
              <figure>
                <h6>Shop</h6>
                <small>Explore</small>
              </figure>
            </a>
          </div>
          {/* <!--Garages--> */}
          {/* <a href="">
            <aside>21</aside>
            <img src={garagesicon} alt="" />
            <figure>
              <h6>Garages</h6>
              // <small>Explore</small>
            </figure>
          </a> */}

          {/* <!--Lands--> */}
          <div
            style={{ display: "flex", width: "100%" }}
            onClick={() => {
              this.setState(
                {
                  type: "Row-House",
                },
                () => this.handleSearchProperty()
              );
            }}
          >
            <a href="#ts-hero">
              <aside>
                {this.state.rowHouseCount && this.state.rowHouseCount}
              </aside>
              <img src={houseicon} alt="" />
              <figure>
                <h6>Row House</h6>
                <small>Explore</small>
              </figure>
            </a>
          </div>
        </section>
      </div>
      // </div>
    );
  }
}

export default withRouter(Home);
function valuetext(value) {
  return `${value} lacs`;
}
