/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "../style.css";
import logo from "../img/aakar/download.png";
import { withRouter } from "react-router-dom";
import baseUrls from "../base-urls";
import adminDetails from "../../adminDetails";
import { errorLog } from "../../common/userlogs/userlogs";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: {},
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      this.setState({ adminData: adminDetails });
    }, 1000);
    }catch(err){
      errorLog("componentDidMount", err ,"" , "" , "" , "")
    }

  }

  OnHome = (divName) => {
    try{
    this.props.history.push(`/${localStorage.getItem("webName")}`);
    setTimeout(() => {
      let productEl = document.getElementById(divName);
      if (productEl) {
        productEl.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 500);
    
  }catch(err){
    errorLog("OnHome", err , "" , "" , "" , "")
  }
  
  };
  onLogout = () => {
    try{
    let webName = localStorage.getItem("webName");
    window.location.href = `/${webName}`;
    localStorage.clear();
    localStorage.setItem("webName", webName);
    }catch(err){
      errorLog("onLogout", err, "", "", "")
    }
  };

  render() {
    return (
      <div>
        {/* <!--*********************************************************************************************************-->
    <!--HEADER **************************************************************************************************-->
    <!--*********************************************************************************************************--> */}
        <header id="ts-header" className="fixed-top">
          {/* <!-- SECONDARY NAVIGATION
        =============================================================================================================--> */}
          <nav id="ts-secondary-navigation" className="navbar p-0">
            <div className="container justify-content-end justify-content-sm-between">
              {/* <!--Left Side--> */}
              {/* <div
                className="navbar-nav d-none d-sm-block"
                style={{
                  height: "30px",
                  marginTop: "10px",
                  fontSize: "medium",
                }}
              >
                <span className="mr-4">
                  <i className="fa fa-phone-square mr-1"></i>
                  {this.state.adminData.mobileNumber}
                </span>
                <a href={`mailto:${this.state.adminData.email}`}>
                  <i className="fa fa-envelope mr-1"></i>
                  {this.state.adminData.email}
                </a>
              </div> */}

              {/* <!--Right Side--> */}
              <div className="navbar-nav flex-row">
                {/* <!--Search Input--> */}
                {/* <input
                  type="text"
                  className="form-control p-2 border-left bg-transparent w-auto"
                  placeholder="Search"
                /> */}

                {/* <!--Currency Select--> */}
                {/* <select className="custom-select bg-transparent ts-text-small border-left" id="currency" name="currency">
                                <option value="1">GBP</option>
                                <option value="2">USD</option>
                                <option value="3">EUR</option>
                            </select> */}

                {/* <!--Language Select--> */}
                {/* <select className="custom-select bg-transparent ts-text-small border-left border-right" id="language" name="language">
                                <option value="1">EN</option>
                                <option value="2">FR</option>
                                <option value="3">DE</option>
                            </select> */}
              </div>
              {/* <!--end navbar-nav--> */}
            </div>
            {/* <!--end container--> */}
          </nav>

          {/* <!--PRIMARY NAVIGATION
        =============================================================================================================--> */}
          <nav
            id="ts-primary-navigation"
            className="navbar navbar-expand-md navbar-light"
          >
            <div className="container">
              {/* <!--Brand Logo--> */}
              <a className="navbar-brand" onClick={() => this.OnHome("home")}>
                {(this.state.adminData.logo !== "noImage" && this.state.adminData.logo !== undefined) && (
                  <img
                    src={
                      this.state.adminData.logo === "noImage"
                        ? "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/not-found.png"
                        : baseUrls.images + this.state.adminData.logo
                    }
                    // alt="companyLogo"
                    style={{ width: "50px" }}
                  />
                )}
              </a>

              {/* <!--Responsive Collapse Button--> */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarPrimary"
                aria-controls="navbarPrimary"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              {/* <!--Collapsing Navigation--> */}
              {localStorage.getItem("isLoggedIn") && (
                <div className="collapse navbar-collapse" id="navbarPrimary">
                  {/* <!--LEFT NAVIGATION MAIN LEVEL
                    =================================================================================================--> */}
                  <ul className="navbar-nav">
                    {/* <!--HOME (Main level)
                        =============================================================================================--> */}
                    <li className="nav-item ts-has-child">
                      {/* <!--Main level link--> */}
                      <a
                        className="nav-link"
                        onClick={() => this.OnHome("home")}
                      >
                        Home
                        <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    {/* <!--end HOME nav-item--> */}
                    {/* <!--ABOUT US (Main level)
                        =============================================================================================--> */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={() => this.OnHome("aboutus")}
                      >
                        About Us
                      </a>
                    </li>
                    {/* <!--end ABOUT US nav-item--> */}
                    {/* <!--LISTING (Main level)
                        =============================================================================================--> */}
                    <li className="nav-item ts-has-child">
                      {/* <!--Main level link--> */}
                      <a
                        className="nav-link"
                        onClick={() => this.OnHome("featured-properties")}
                      >
                        Property
                      </a>
                    </li>
                    {/* <!--end LISTING nav-item--> */}

                    {/* <!--LISTING (Main level)
                        =============================================================================================--> */}
                    {/* <li className="nav-item ts-has-child">

                                        <a className="nav-link" target="_blank" href="https://aus-ai.firebaseapp.com/dekho/5e048cad3bfdaa001044e07a/0">VR Tour</a>


                                    </li> */}
                    {/* <!--end LISTING nav-item--> */}

                    {/* <!--CONTACT (Main level)
                        =============================================================================================--> */}
                    <li className="nav-item">
                      <a
                        className="nav-link mr-2"
                        //  href="#contact"

                        onClick={() => this.OnHome("contact")}
                      >
                        Contact
                      </a>
                    </li>
                    {/* <!--end CONTACT nav-item--> */}
                  </ul>
                  {/* <!--end Left navigation main level--> */}
                  <ul className="navbar-nav ml-auto">
                    {/* 
                        <!--LOGOUT  (Main level)
                        =============================================================================================--> */}
                    <li className="nav-item">
                      <a className="nav-link" onClick={() => this.onLogout()}>
                        Logout
                      </a>
                    </li>
                  </ul>
                  {/* <!--RIGHT NAVIGATION MAIN LEVEL
                    =================================================================================================--> */}
                  {/* <!--end Right navigation--> */}
                </div>
              )}

              {/* <!--end navbar-collapse--> */}
            </div>
            {/* <!--end container--> */}
          </nav>
          {/* <!--end #ts-primary-navigation.navbar--> */}
        </header>
        {/* <!--end Header--> */}
      </div>
    );
  }
}
export default withRouter(Header);
