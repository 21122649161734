import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import userDetails from "./login-userDetails";
// import adminDetails from "./adminDetails";
import RouteContainer from "./RouteContainer";
// import axios from "axios";
// import baseUrls from "./common/utils/base-urls";

function App() {

  return (
    <div className="App">
      <RouteContainer></RouteContainer>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
