import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import adminDetails from "../adminDetails";
import "../components/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#36cca5",
  },
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

var r = document.querySelector(":root");

export default function ShareLinkDialog(props) {
  const { shareLinkDialog, setShareLinkDialogCancel } = props;

  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      myFunctionSet(
        adminDetails.themeColor.backgroundColor,
        adminDetails.themeColor.fontColor
      );
    }, 1000);
  }, []);

  const onCopyText = () => {
    const copyText = document.getElementById("shareText");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    // Dialog Close after copied the link
    setShareLinkDialogCancel();
  };

  const myFunctionSet = (color, fontcolor) => {
    r.style.setProperty("--themecolor", color);
    r.style.setProperty("--fontcolor", fontcolor);
  };

  return (
    <Dialog
      className="input-field"
      open={shareLinkDialog.isOpen}
      aria-labelledby="form-dialog-title"
      style={{ width: "100% !important" }}
      fullWidth
    >
      <DialogTitle id="form-dialog-title" className="text-dynamicColor">
        <strong>{shareLinkDialog.title}</strong>
      </DialogTitle>
      <DialogContent style={{ display: "flex" }}>
        <TextField
          readonly="readonly"
          autoFocus
          margin="dense"
          id="shareText"
          name="link"
          type="text"
          value={shareLinkDialog.shareLink}
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        {/* <Button
          className="dynamicButtonColor"
          type="button"
          onClick={onCopyText}
          style={{ width: "90px" }}
          variant="contained"
        >
          <FileCopyIcon className="marginicon" />
          Copy
        </Button> */}
        <a
          onClick={onCopyText}
          className="ts-scroll btn btn-outline-light btn-sm mt-5 d-none d-md-inline-block"
        >
          <FileCopyIcon className="marginicon" />
          Copy Link
        </a>
      </DialogActions>
    </Dialog>
  );
}
