import React, { Component } from "react";
import "./footer.css";
import whatsappicon from "../img/aakar/whatsapp.svg";
import calicon from "../img/aakar/calendar.svg";
import { analyticsFunction, errorLog } from "../../common/userlogs/userlogs";
import adminDetails from "../../adminDetails";
import { withRouter } from "react-router-dom";
import baseUrls from "../base-urls";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: {},
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      this.setState({ adminData: adminDetails });
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err, "", "", "")
  }
}

  OnHome = (divName) => {
    try{
    // if (this.props.history) {
    this.props.history.push(`/${localStorage.getItem("webName")}`);
    setTimeout(() => {
      let productEl = document.getElementById(divName);
      if (productEl) {
        productEl.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 500);
    // }
  }catch(err){
    errorLog("OnHome", err, "", "", "")
  }
  };
  // whatsapp Button Click
  whatsappClick = () => {
    try{
    analyticsFunction("Whatsapp Click", "");
    window.open(
      `https://wa.me/${this.state.adminData.whatsappNumber}`,
      "_blank"
    );
    }catch(err){
      errorLog("whatsappClick", err ,"", "", "")
    }
  };

  // Calendory Click
  openCalendly = () => {
    try{
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "flex";
    document.getElementById("popup1").style.display = "flex";
    document.body.style.overflow = "hidden";
    analyticsFunction("Calendly Click", "");
    }catch(err){
      errorLog("openCalendly", err, "", "", "")
    }
  };
  // close Calendory
  closeCalendly = () => {
    try{
    document.getElementById("overlay").style.display = "none";
    document.getElementById("close").style.display = "none";
    document.getElementById("popup1").style.display = "none";
    document.body.style.overflow = "auto";
    }catch(err){
      errorLog("closeCalendly", err, "", "", "")
    }
  };

  render() {
    return (
      <div>
        {/* <!--*********************************************************************************************************-->
    <!--************ FOOTER *************************************************************************************-->
    <!--*********************************************************************************************************--> */}
        <div className="footericon">
          <div
            className="closebtnform closebtncalendly "
            onClick={this.closeCalendly}
            id="close"
          // style={{ display: "none" }}
          >
            {/* <img alt="close" src={closeicon} style={{ cursor: "pointer" }}></img> */}
          </div>
          <div id="overlay" onClick={this.closeCalendly} style={{ zIndex: 1000 }}>
            <div
              id="popup1"
              className="calendly-inline-widget calendar"
              data-url="https://calendly.com/shilpmis/schedule-a-demo"
            ></div>
          </div>
          <div
            className="shadowicon"
            style={{ marginBottom: "20px" }}
            onClick={() => this.whatsappClick()}
          >
            {/* <img src={whatsappicon} /> */}
            <i className="fa fa-whatsapp dynamicIconColor" aria-hidden="true" style={{ fontSize: "27px", textAlign: "center" }}></i>
          </div>
          <div className="shadowicon" onClick={this.openCalendly}>
            <i className="fa fa-calendar dynamicIconColor" style={{ fontSize: "27px", textAlign: "center" }}></i>
            {/* <img src={calicon}></img> */}
          </div>
        </div>
        <footer id="ts-footer">
          {/* <!--MAIN FOOTER CONTENT
=========================================================================================================--> */}
          <section id="ts-footer-main">
            <div className="container">
              <div className="row">
                {/* <!--Brand and description--> */}
                <div className="col-md-6">
                  <a href="#" className="brand">
                    <img src="assets/img/logo.png" alt="" />
                  </a>
                  <p className="mb-4">
                    {this.state.adminData.footer
                      ? this.state.adminData.footer
                      : "We are happy to find best property for you..!"}
                  </p>
                  {/* <a href="#" className="btn btn-outline-dark mb-4">Contact Us</a> */}
                </div>

                {/* <!--Navigation--> */}
                <div className="col-md-2">
                  <h4 className="text-dynamicColor">Navigation</h4>
                  <nav className="nav flex-row flex-md-column mb-4">
                    <ul className="navbar-nav">
                      {/* <!--HOME (Main level)
                        =============================================================================================--> */}
                      <li className="nav-item ts-has-child">
                        <a
                          className="nav-link"
                          onClick={() => this.OnHome("home")}
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item ts-has-child">
                        <a
                          className="nav-link"
                          onClick={() => this.OnHome("aboutus")}
                        >
                          About US
                        </a>
                      </li>
                      <li className="nav-item ts-has-child">
                        <a
                          onClick={() => this.OnHome("featured-properties")}
                          className="nav-link"
                        >
                          Property
                        </a>
                      </li>
                      <li className="nav-item ts-has-child">
                        {/* <a  className="nav-link"  target="_blank" href="https://aus-ai.firebaseapp.com/dekho/5e048cad3bfdaa001044e07a/0">VR Tour</a> */}
                        <a
                          onClick={() => this.OnHome("contact")}
                          className="nav-link"
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                    {/* <a href="#" className="nav-link">Submit Property</a> */}
                  </nav>
                </div>
                {/* <!--Contact Info--> */}
                <div className="col-md-4">
                  <h4 className="text-dynamicColor">Contact</h4>
                  <address className="ts-text-color-light">
                    {this.state.adminData.address
                      ? this.state.adminData.address.address1 +
                      this.state.adminData.address.address2
                      : ""}
                    <br />
                    {this.state.adminData.address
                      ? this.state.adminData.address.city +
                      ", " +
                      this.state.adminData.address.state +
                      ", " +
                      this.state.adminData.address.country
                      : ""}
                    <br />
                    <strong className="btn-link" >Email: </strong>
                    <a
                      href={`mailto:${this.state.adminData.email}`}
                    // className="btn-link"
                    >
                      {this.state.adminData.email}
                    </a>
                    <br />
                    <strong className="btn-link">Phone:</strong>
                    <a
                      href={`tel: ${this.state.adminData.mobileNumber}`}

                    >
                      {" "}
                      {"+91 " + this.state.adminData.mobileNumber}
                    </a>
                    <br />
                  </address>
                </div>
              </div>
              {/* <!--end row--> */}
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!--end ts-footer-main--> */}

          {/* <!--SECONDARY FOOTER CONTENT
=========================================================================================================--> */}
          <section id="ts-footer-secondary" style={{ position: "fixed", bottom: "0%", width: "100%", background: "white" }}>
            <div className="container">
              {/* <!--Copyright--> */}
              <div className="ts-copyright">
                <a href="https://melzo.com" target="_blank">
                  Powered by Melzo
                  {/* {this.state.adminData.logo && (
                    <img
                      src={baseUrls.images + this.state.adminData.logo} width="25px"
                    ></img>
                  )} */}
                </a>
              </div>

              {/* <!--Social Icons--> */}
              <div className="ts-footer-nav">
                <nav className="nav">
                  {this.state.adminData.socialMedia &&
                    this.state.adminData.socialMedia.facebook && (
                      <a
                        href={`${this.state.adminData.socialMedia.facebook}`}
                        target="_blank"
                        className="nav-link"
                      >
                        <i className="fa fa-facebook dynamicIconColor"></i>
                      </a>
                    )}
                  {this.state.adminData.socialMedia &&
                    this.state.adminData.socialMedia.twitter && (
                      <a
                        href={`${this.state.adminData.socialMedia.twitter}`}
                        target="_blank"
                        className="nav-link"
                      >
                        <i className="fa fa-twitter dynamicIconColor"></i>
                      </a>
                    )}
                  {this.state.adminData.socialMedia &&
                    this.state.adminData.socialMedia.pinterest && (
                      <a
                        href={`${this.state.adminData.socialMedia.pinterest}`}
                        target="_blank"
                        className="nav-link"
                      >
                        <i className="fa fa-pinterest dynamicIconColor"></i>
                      </a>
                    )}
                  {this.state.adminData.socialMedia &&
                    this.state.adminData.socialMedia.youtube && (
                      <a
                        href={`${this.state.adminData.socialMedia.youtube}`}
                        target="_blank"
                        className="nav-link"
                      >
                        <i className="fa fa-youtube dynamicIconColor"></i>
                      </a>
                    )}
                </nav>
              </div>
              {/* <!--end ts-footer-nav--> */}
            </div>
            {/* <!--end container--> */}
          </section>
          {/* <!--end ts-footer-secondary--> */}
        </footer>
        {/* <!--end #ts-footer--> */}
      </div>
    );
  }
}

export default withRouter(Footer);
