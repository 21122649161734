import React, { Component } from "react";
import Header from "./header/Header";
import "./style.css";
import "./bootstrap.min.css";

import Home from "./home/Home";
import Properties from "./Properties/Properties";
import Latest from "./Latest-Listings/Latest";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import AboutUs from "./AboutUs/AboutUs";
import axios from "axios";
import baseUrls from "../common/utils/base-urls";
import { getUserToken } from "../common/utils/utils-functions";
import Login from "./Login/Login";
import Aboutusdetail from "./AboutUs/Aboutusdetail";
import PropertyDetails from "./Properties/PropertyDetails";
import AgentDetail from "./AboutUs/AgentDetail";
import SearchProperties from "./Properties/SearchProperties";
import { analyticsFunction, errorLog } from "../common/userlogs/userlogs";

var r = document.querySelector(":root");

export default class Mainpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerData: {
        tagline: "no content available",
        title: "no content available",
        images: [],
      },
      about: {
        content: "no content available",
        title: "no content available",
      },
      property: [],
      home: [],
      header: {},
      isLoggedIn: false,
      aboutusDetails: false,
      propertyDetails: false,
      agentDetails: false,
      searchProperty: false,
    };
  }

  componentDidMount() {
    try {
      const { webName } = this.props.match.params;
      if (localStorage.getItem("webName") !== webName) {
        localStorage.clear();
      } else if (localStorage.getItem("isLoggedIn")) {
        this.setState({
          isLoggedIn: true,
        });
      }
let parentUid = ""
      axios
        .get(`${baseUrls.serverUrl}users/getAakarUserDetails/` + webName)
        .then((res) => {
          parentUid = res.data[0].uid;
          // ******************************************************************** //

          axios
            .post("https://ptpvr.com/paidview/isviewvalid", {
              product: "Aakar",
              uid: res.data[0].uid,
            })
            .then((response) => {
              console.log("got data", response.data);
              if (response.data.isAllowed || response.data.isAccessNotGiven) {
                // axios
                //   .put(
                //     `https://ptpvr.com/projects/updateview/${
                //       melzoProjectJson.id
                //     }`,
                //     {
                //       views: melzoProjectJson.views + 1
                //     }
                //   )
                //   .then(response => {
                if (response.data.isPayAsYouGo) {
                  this.postSession(parentUid);
                }
                analyticsFunction("Aakar Session", "");
                // });

                // TODO: Improve
              } else {
                window.location.href = "/warning";
              }
            });
          // ******************************************************************** //
          axios
            .post(`${baseUrls.serverUrl}property/getProperty`, {
              uid: res.data[0].uid,
            })
            .then((propertyResponse) => {
              this.setState({
                property: propertyResponse.data,
                about: res.data[0].aakarConfiguration.aboutUs,
                header: {
                  email: res.data[0].email,
                  contactNumber: res.data[0].mobileNumber,
                  logo: res.data[0].photoURL,
                  webName: webName,
                },
                bannerData: res.data[0].aakarConfiguration.banner,
              });
              this.myFunctionSet(
                res.data[0].aakarConfiguration.themeColor.backgroundColor,
                res.data[0].aakarConfiguration.themeColor.fontColor
              );
            })
            .catch((err) => {
              console.error("err", err);
            });
        })
        .catch((error) => {
          console.error("error=>", error);
        });
    } catch (err) {
      errorLog("componentDidMount", err, "", "", "");
    }
  }

  postSession(uid) {
    try {
      axios
        .post("https://ptpvr.com/usage/session", {
          uid: uid,
          product: "aakar",
        })
        .then((response) => {
          console.log("usage session then", response);
        })
        .catch((err) => {
          console.log("error",err)
        });
    } catch (err) {
      errorLog("postSession", err, "viewer");
    }
  }

  myFunctionSet = (color, fontcolor) => {
    try {
      r.style.setProperty("--themecolor", color);
      r.style.setProperty("--fontcolor", fontcolor);
    } catch (err) {
      errorLog("myFunctionSet", err, "", "", "");
    }
  };

  userloggedin = () => {
    try {
      this.setState({
        isLoggedIn: true,
      });
    } catch (err) {
      errorLog("userloggedin", err, "", "", "");
    }
  };

  searchProperty = () => {
    try {
      this.setState({
        searchProperty: true,
      });
    } catch (err) {
      errorLog("searchProperty", err, "", "", "");
    }
  };

  render() {
    const { webName } = this.props.match.params;

    return (
      <div>
        {!this.state.isLoggedIn && (
          <Login webName={webName} loginCallBack={() => this.userloggedin()} />
        )}
        {this.state.isLoggedIn && !this.state.searchProperty && (
          <div className="ts-page-wrapper ts-homepage">
            <Header />
            <Home propertyCallBack={() => this.searchProperty()} />
            <AboutUs />
            <Properties />
            <Latest />
            <Contact />
            <Footer></Footer>
          </div>
        )}
        {this.state.isLoggedIn && this.state.searchProperty && (
          <SearchProperties />
        )}
      </div>
    );
  }
}
