import React, { Component } from "react";
import Header from "../header/Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import floorplan1 from "../img/aakar/img-floor-plan-01.jpg";
// import floorplan2 from "../img/aakar/img-floor-plan-02.jpg";
// import Conditioning from "../img/aakar/amenities/air-conditioning.png";
// import gymimg from "../img/aakar/amenities/gym.png";
// import swimmering from "../img/aakar/amenities/swimmer.png";
// import laundrying from "../img/aakar/amenities/laundry.png";
// import worldgriding from "../img/aakar/amenities/world-grid.png";
// import Heatinging from "../img/aakar/amenities/air-conditioner.png";
// import alarming from "../img/aakar/amenities/alarm-clock.png";
// import blindsing from "../img/aakar/amenities/blinds.png";
import "./properties.css";
import "../style.css";
import Footer from "../Footer/Footer";
import axios from "axios";
import baseUrls from "../base-urls";
import userDetail from "../../common/userlogs/userDetail";
import adminDetails from "../../adminDetails";
import ShareLinkDialog from "../shareDialog";
import { errorLog } from "../../common/userlogs/userlogs";

var r = document.querySelector(":root");

export default class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // propertyDetail: []
      propertyId: "",
      name: "",
      address: "",
      price: "",
      images: [],
      type: "",
      currentStatus: "",
      area: "",
      roomCount: "",
      bedroomCount: "",
      bathroomCount: "",
      features: [],
      description: "",
      mapURL: "",
      floorPlans: [],
      otherConfig: "",
      garageCount: 0,
      contactNumber: "",
      website: "",
      contactEmail: "",
      videos: "",
      agentName: "",
      agentMobileNo: "",
      allData: [],
      isEnable: false,

      infoUserName: "",
      infoEmail: "",
      infoMessage: "",
      vrProjectId: "",
      isVrReady: false,
      isDataLoad: false,
      similarProperty: [],
      shareLinkDialog: {
        isOpen: false,
        title: "",
        shareLink: "",
      },
    };
  }

  componentDidMount() {
    let { propertyId } = this.props.match.params;
    // let docid = "60c05c137aa5d10019bd68b4";
    setTimeout(() => {
      console.log("Property Detaoils", adminDetails.property);
      if (propertyId !== "" && propertyId !== undefined) {
        axios
          .get(baseUrls.serverUrl + `property/${propertyId}`)
          .then((res) => {
            axios
              .post(`${baseUrls.serverUrl}property/getProperty`, {
                uid: res.data.uid,
              })
              .then((propertyResponse) => {
                let filterProperty = propertyResponse.data.filter(
                  (filterData) => {
                    return (
                      filterData.type == res.data.type ||
                      filterData.address
                        .toLowerCase()
                        .includes(res.data.address.toLowerCase())
                    );
                  }
                );

                filterProperty = filterProperty.filter(
                  (property) => property._id !== res.data._id
                );

                this.setState(
                  {
                    propertyId: propertyId,
                    name: res.data.name,
                    address: res.data.address,
                    price: res.data.price,
                    images: res.data.images,
                    type: res.data.type,
                    currentStatus: res.data.currentStatus,
                    area: res.data.area,
                    roomCount: res.data.roomCount,
                    bedroomCount: res.data.bedroomCount,
                    bathroomCount: res.data.bathroomCount,
                    features: res.data.features,
                    description: res.data.description,
                    mapURL: res.data.mapURL,
                    floorPlans: res.data.floorPlans,
                    otherConfig: res.data.otherConfig.propertyId,
                    garageCount: res.data.garageCount,
                    contactNumber: res.data.mobileNumber,
                    website: res.data.website,
                    contactEmail: res.data.email,
                    videos: res.data.videos,
                    vrProjectId: res.data.vrProject
                      ? res.data.vrProject.projectId
                      : "",
                    isVrReady: res.data.vrProject
                      ? res.data.vrProject.isVrReady
                      : false,
                    similarProperty: filterProperty,
                  },
                  () => {
                    this.myFunctionSet(
                      adminDetails.themeColor.backgroundColor,
                      adminDetails.themeColor.fontColor
                    );
                    res.data.agents.map((obj) => {
                      axios
                        .get(baseUrls.serverUrl + `agent/${obj}`)
                        .then((res) => {
                          let allData = this.state.allData;
                          allData.push(res.data);
                          this.setState(
                            {
                              agentName: res.data.name,
                              agentMobileNo: res.data.mobileNumber,
                              allData: allData,
                            },
                            () => {
                              let tmpData = this.state.mapURL.split(" ");
                              if (tmpData.length > 1) {
                                let tmpString1 = tmpData[1].replace("src=", "");
                                this.setState({
                                  mapURL: tmpData[1].replace("src=", ""),
                                });
                              }
                            }
                          );
                        })
                        .catch((err) => {
                          console.error("Error :: ", err, "", "", "");
                        });
                    });
                  }
                );
              })
              .catch((err) => {
                console.error(
                  "error occured in fetching similar property",
                  err
                );
              });
          })
          .catch((err) => {
            console.error("Error :: ", err, "", "", "", "");
            window.location.href = "/" + localStorage.getItem("webName");
          });
      } else {
        window.location.href = "/" + localStorage.getItem("webName");
      }
    }, 1000);
  }

  setShareLinkDialogCancel = () => {
    try {
      this.setState({
        shareLinkDialog: {
          isOpen: false,
          title: "",
          shareLink: "",
        },
      });
    } catch (err) {
      errorLog("setShareLinkDialogCancel", err, "", "", "");
    }
  };

  propertyDetails = (propertyId) => {
    try {
      window.location.href =
        "/" + localStorage.getItem("webName") + "/" + propertyId;
    } catch (err) {
      errorLog("propertyDetails", err, "", "", "", "");
    }
  };

  myFunctionSet = (color, fontcolor) => {
    try {
      r.style.setProperty("--themecolor", color);
      r.style.setProperty("--fontcolor", fontcolor);
    } catch (err) {
      errorLog("myFunctionSet", err, "", "", "");
    }
  };

  //On Inquiry Form Change textBox Value
  onChangeForm = (e) => {
    try {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } catch (err) {
      errorLog("onChangeForm", err, "", "", "");
    }
  };

  //Inquire Submit
  onSubmitInquire = () => {
    if (this.state.infoUserName == "") {
      alert("Enter User Name");
      return;
    }
    if (this.state.infoEmail == "") {
      alert("Enter User Email");
      return;
    }
    if (this.state.infoMessage == "") {
      alert("Enter Message");
      return;
    }

    let { propertyId } = this.props.match.params;
    let tmpObj = {
      name: this.state.infoUserName,
      email: this.state.infoEmail,
      expo: localStorage.getItem("webName"),
      country: "",
      mobileNumber: userDetail.mobileNumber,
      subject: "",
      message: this.state.infoMessage,
      formName: "inquiry",
      productName: "Melzo Aakar",
      projectId: propertyId,
      uid: userDetail.id,
    };
    axios
      .post(baseUrls.serverUrl + `userform`, tmpObj)
      .then((res) => {
        this.setState({
          infoUserName: "",
          infoEmail: "",
          infoMessage: "",
        });
      })
      .catch((err) => {
        console.error("err :: ", err, "", "", "");
      });
  };

  render() {
    return (
      <div>
        <Header></Header>
        {/* <!--PAGE TITLE
            =========================================================================================================--> */}
        <section id="page-title" className="margintoptitle">
          <div className="container">
            <div className="d-block d-sm-flex justify-content-between">
              {/* <!--Title--> */}
              <div className="ts-title mb-0">
                <h1 className="text-dynamicColor">{this.state.name}</h1>
                <h5 className="ts-opacity__90">
                  <i className="fa fa-map-marker text-primary"></i>
                  {this.state.address}
                </h5>
              </div>
              {/* <!--Price--> */}
              <h3>
                <span className="btn btn-primary">
                  &#8377;
                  {parseInt(this.state.price) > 100000000
                    ? parseInt(this.state.price) / 10000000 + " Crs"
                    : parseInt(this.state.price) > 10000000
                    ? parseInt(this.state.price) / 10000000 + " Cr"
                    : parseInt(this.state.price) > 1000000
                    ? parseInt(this.state.price) / 100000 + " Lacs"
                    : parseInt(this.state.price) > 100000
                    ? parseInt(this.state.price) / 100000 + " Lac"
                    : this.state.price}
                </span>
              </h3>
            </div>
          </div>
        </section>
        {/* <!--GALLERY CAROUSEL
            =========================================================================================================--> */}
        <section id="gallery-carousel">
          {this.state.isVrReady && (
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ height: "60vh" }}>
                  <iframe
                    src={`https://aus-ai.firebaseapp.com/dekho/${this.state.vrProjectId}/0`}
                    className="iframeborder"
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              </div>
            </div>
          )}
          {/* <div className="owl-carousel ts-gallery-carousel ts-gallery-carousel__multi" data-owl-dots="1" data-owl-items="3" data-owl-center="1" data-owl-loop="1"> */}
          <OwlCarousel
            items={this.state.images ? this.state.images.length : 0}
            autoPlay={true}
            loop
            className="ts-gallery-carousel ts-gallery-carousel__multi"
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
                loop: true,
              },
              600: {
                items: 1,
                loop: true,
              },
              700: {
                items: 2,
                loop: true,
              },
              1000: {
                items: 3,
                loop: true,
              },
            }}
          >
            {this.state.images &&
              this.state.images.map((obj,index) => {
                return (
                  <div className="slide slidedetail" key={index}>
                    <div
                      className="ts-image"
                      style={{
                        backgroundImage: `url(${baseUrls.images + obj})`,
                      }}
                    ></div>
                  </div>
                );
              })}
          </OwlCarousel>
          {/* </div> */}
        </section>
        {/* <!--CONTENT
            =========================================================================================================--> */}
        <section id="content">
          <div className="container">
            <div className="row flex-wrap-reverse">
              {/* <!--LEFT SIDE
                        =============================================================================================--> */}
              <div className="col-md-5 col-lg-4">
                {/* <!--DETAILS
                            =========================================================================================--> */}
                <section>
                  <h3 className="text-dynamicColor">Details</h3>
                  <div className="ts-box">
                    <dl className="ts-description-list__line mb-0">
                      <dt>ID:</dt>
                      <dd>{this.state.propertyId}</dd>
                      <dt>Category:</dt>
                      <dd>{this.state.type}</dd>
                      <dt>Status:</dt>
                      <dd>{this.state.currentStatus}</dd>
                      <dt>Area:</dt>
                      <dd>
                        {this.state.area}m<sup>2</sup>
                      </dd>
                      <dt>Rooms:</dt>
                      <dd>{this.state.roomCount}</dd>
                      <dt>Bathrooms:</dt>
                      <dd>{this.state.bathroomCount}</dd>
                      <dt>Bedrooms:</dt>
                      <dd>{this.state.bedroomCount}</dd>

                      <dt>Garages:</dt>
                      <dd>{this.state.garageCount}</dd>
                    </dl>
                  </div>
                </section>
                {/* <!--CONTACT THE AGENT
                            =========================================================================================--> */}
                <section className="contact-the-agent">
                  <h3 className="text-dynamicColor">Contact the Agent</h3>
                  <div className="ts-box">
                    <OwlCarousel
                      style={{ zIndex: 0 }}
                      items={1}
                      autoplay={true}
                      loop
                      nav
                      navText={
                        <i
                          className="fa fa-arrow-right"
                          style={{ fontSize: "48px" }}
                        />
                      }
                      className="ts-gallery-carousel ts-gallery-carousel__multi"
                      responsiveClass={true}
                    >
                      {this.state.allData &&
                        this.state.allData.map((data,index) => {
                          return (
                            <div className="ts-center__vertical mb-4" key={index}>
                              {/* {data.name} */}
                              {/* <!--Image--> */}

                              <a
                                className="ts-circle p-5 mr-4 ts-shadow__sm agentimg"
                                style={{
                                  backgroundImage: `url(${
                                    data.photo
                                      ? baseUrls.images + data.photo
                                      : "/static/media/img-person-05.32cb1926.jpg"
                                  })`,
                                }}
                              ></a>

                              {/* <!--Phone contact--> */}
                              <figure className="mb-0">
                                <h5 className="mb-0">{data.name}</h5>
                                <p className="mb-0">
                                  <i className="fa fa-phone-square ts-opacity__50 mr-2"></i>
                                  {data.mobileNumber}
                                </p>
                              </figure>
                            </div>
                          );
                        })}
                    </OwlCarousel>

                    {/* <!--Agent Image & Phone--> */}

                    {/* <!--Agent contact form--> */}
                    {/* <!--Name--> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="infoUserName"
                        placeholder="Your Name"
                        value={this.state.infoUserName}
                        onChange={this.onChangeForm}
                      />
                    </div>
                    {/* <!--Email--> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="infoEmail"
                        placeholder="Your Email"
                        value={this.state.infoEmail}
                        onChange={this.onChangeForm}
                      />
                    </div>
                    {/* <!--Message--> */}
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="form-contact-message"
                        rows="3"
                        name="infoMessage"
                        placeholder="Hi, I want to have more information about property #156461"
                        value={this.state.infoMessage}
                        onChange={this.onChangeForm}
                      ></textarea>
                    </div>
                    {/* <!--Submit button--> */}
                    <div className="form-group clearfix mb-0">
                      <button
                        type="submit"
                        className="btn btn-primary float-right"
                        id="form-contact-submit"
                        onClick={() => this.onSubmitInquire()}
                      >
                        Send a Message
                      </button>
                    </div>
                  </div>
                </section>
                {/* <!--LOCATION
                        =============================================================================================--> */}
                <section id="location">
                  <h3 className="text-dynamicColor">Location</h3>
                  <div className="ts-box">
                    <dl className="ts-description-list__line mb-0">
                      <dt>
                        <i className="fa fa-map-marker ts-opacity__30 mr-2"></i>
                        Address:
                      </dt>
                      <dd className="border-bottom pb-2">
                        {this.state.address
                          ? this.state.address
                          : "1350 Arbutus Drive, LD"}{" "}
                      </dd>
                      <dt>
                        <i className="fa fa-phone-square ts-opacity__30 mr-2"></i>
                        Phone:
                      </dt>
                      <dd className="border-bottom pb-2">
                        {this.state.contactNumber
                          ? this.state.contactNumber
                          : "No Contact Number"}
                      </dd>

                      <dt>
                        <i className="fa fa-envelope ts-opacity__30 mr-2"></i>
                        Email:
                      </dt>
                      <dd className="border-bottom pb-2">
                        <a
                          href={`mailto:${this.state.contactEmail}`}
                          target="_blank"
                        >
                          {this.state.contactEmail
                            ? this.state.contactEmail
                            : "hello@property.com"}
                        </a>
                      </dd>
                      {this.state.website && this.state.website.length > 0 && (
                        <dt>
                          <i className="fa fa-globe ts-opacity__30 mr-2"></i>
                          Website:
                        </dt>
                      )}
                      {this.state.website && this.state.website.length > 0 && (
                        <dd style={{ overflow: "hidden" }}>
                          <a
                            href={this.state.website}
                            target="_blank"
                          >
                            {this.state.website.split("www.")[1].split(".com")[0]}
                          </a>
                        </dd>
                      )}
                    </dl>
                  </div>
                </section>
                {/* <!--ACTIONS
                        =============================================================================================--> */}
                <section id="actions">
                  <div className="d-flex justify-content-between">
                    <a
                      href="#"
                      className="btn btn-light mr-2 w-100"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add to favorites"
                    >
                      <i className="fa fa-star"></i>
                    </a>
                    <a
                      onClick={() => window.print()}
                      className="btn btn-light mr-2 w-100"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Print"
                    >
                      <i className="fa fa-print"></i>
                    </a>
                    <a
                      href="#"
                      className="btn btn-light mr-2 w-100"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add to compare"
                    >
                      <i className="fa fa-exchange"></i>
                    </a>
                    <a
                      onClick={() =>
                        this.setState({
                          shareLinkDialog: {
                            isOpen: true,
                            title: "Share Property Link",
                            shareLink: `http://localhost:3000/melzo-demo/${this.state.propertyId}`,
                          },
                        })
                      }
                      className="btn btn-light w-100"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Share property"
                    >
                      <i className="fa fa-share-alt"></i>
                    </a>
                  </div>
                </section>
              </div>
              {/* <!--end col-md-4--> */}
              {/* <!--RIGHT SIDE
                        =============================================================================================--> */}
              <div className="col-md-7 col-lg-8">
                {/* <!--QUICK INFO
                            =========================================================================================--> */}
                <section id="quick-info">
                  <h3 className="text-dynamicColor">Quick Info</h3>
                  {/* <!--Quick Info--> */}
                  <div className="ts-quick-info ts-box">
                    {/* <!--Row--> */}
                    <div className="row no-gutters">
                      {/* <!--Bathrooms--> */}
                      <div className="col-sm-3">
                        <div className="ts-quick-info__item infoimg1">
                          <h6>Bathrooms</h6>
                          <figure>{this.state.bathroomCount}</figure>
                        </div>
                      </div>
                      {/* <!--Bedrooms--> */}
                      <div className="col-sm-3">
                        <div className="ts-quick-info__item infoimg2">
                          <h6>Bedrooms</h6>
                          <figure>{this.state.bedroomCount}</figure>
                        </div>
                      </div>
                      {/* <!--Area--> */}
                      <div className="col-sm-3">
                        <div className="ts-quick-info__item infoimg3">
                          <h6>Area</h6>
                          <figure>
                            {this.state.area}m<sup>2</sup>
                          </figure>
                        </div>
                      </div>
                      {/* <!--Garages--> */}
                      <div className="col-sm-3">
                        <div className="ts-quick-info__item infoimg4">
                          <h6>Garages</h6>
                          <figure>{this.state.garageCount}</figure>
                        </div>
                      </div>
                    </div>
                    {/* <!--end row--> */}
                  </div>
                  {/* <!--end ts-quick-info--> */}
                </section>
                {/* <!--DESCRIPTION
                            =========================================================================================--> */}
                <section id="description">
                  <h3 className="text-dynamicColor">Description</h3>
                  <p>
                    {this.state.description &&
                      (this.state.description.length > 0
                        ? this.state.description
                        : "No Description Available")}
                  </p>
                </section>

                {/* <!--FEATURES
                            =========================================================================================--> */}
                <section id="features">
                  <h3 className="text-dynamicColor">Amenities</h3>
                  {this.state.features && this.state.features.length > 0 && (
                    <ul className="list-unstyled ts-list-icons ts-column-count-4 ts-column-count-sm-2 ts-column-count-md-2">
                      {this.state.features.map((obj,index) => {
                        return (
                          <li key={index}>
                            <i className="fa fa-certificate"></i>
                            {obj.title}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </section>
                {/* <!--MAP
                            =========================================================================================--> */}
                {this.state.mapURL !== undefined && this.state.mapURL !== "" && (
                  <section id="map-location" style={{ marginBottom: "2rem" }}>
                    <h3 className="text-dynamicColor">Map</h3>
                    <div
                      id="map-container-google-1"
                      className="z-depth-1-half map-container"
                      style={{ height: "500px" }}
                    >
                      <iframe
                        src={this.state.mapURL.replace('"', "")}
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </section>
                )}
                {/* 
                        <!--FLOOR PLANS
                            =========================================================================================--> */}
                {this.state.floorPlans && this.state.floorPlans.length > 0 && (
                  <section id="floor-plans" style={{ marginBottom: "2rem" }}>
                    <h3 className="text-dynamicColor">Floor Plans</h3>
                    {this.state.floorPlans &&
                      this.state.floorPlans.map((obj, index) => {
                        return (
                          <a
                            href={`#collapse-floor-${obj.name}`}
                            className="ts-box d-block mb-2 py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls={`collapse-floor-${obj.name}`}
                            key={index}
                          >
                            {obj.name}
                            <div
                              className="collapse"
                              id={`collapse-floor-${obj.name}`}
                            >
                              <img
                                src={baseUrls.images + obj.image}
                                alt=""
                                className="w-100"
                              />
                            </div>
                          </a>
                        );
                      })}
                  </section>
                )}
                {/* <!--VIDEO
                        =============================================================================================--> */}
                {this.state.videos &&
                  this.state.videos !== undefined &&
                  this.state.videos !== "" && (
                    <section id="video" style={{ marginBottom: "2rem" }}>
                      <h3 className="text-dynamicColor">Video</h3>
                      <div className="embed-responsive embed-responsive-16by9 rounded ts-shadow__md">
                        <iframe
                          src={baseUrls.s3BucketVideos + this.state.videos}
                          width="640"
                          height="360"
                          autoPlay
                          frameBorder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allowfullscreen
                        ></iframe>
                      </div>
                    </section>
                  )}
              </div>
              {/* <!--end col-md-8--> */}
            </div>
            {/* <!--end row--> */}
          </div>
          {/* <!--end container--> */}
        </section>
        {/* <!--SIMILAR PROPERTIES
        =============================================================================================================--> */}
        {this.state.similarProperty && this.state.similarProperty.length > 0 && (
          <section id="similar-properties">
            <div className="container">
              <div className="row">
                <div className="offset-lg-4 col-sm-12 col-lg-8">
                  <hr className="mb-5" />
                  <h3 className="text-dynamicColor">Similar Properties</h3>
                  {/* Filter property by Type of property and address of the property */}
                  {this.state.similarProperty.map((property, index) => {
                    return (
                      //                    /* <!--Item--> */
                      <div className="card ts-item ts-item__list ts-card" key={index}>
                        {/* <!--Ribbon--> */}
                        <div className="ts-ribbon">
                          <i className="fa fa-thumbs-up"></i>
                        </div>
                        <div className="ts-ribbon-corner">
                          <span>Updated</span>
                        </div>
                        {/* <!--Card Image--> */}
                        <a
                          className="card-img propertiesimg"
                          style={{
                            backgroundImage: `url(${
                              baseUrls.images + property.images[0]
                            })`,
                          }}
                        ></a>
                        {/* <!--Card Body--> */}
                        <div className="card-body">
                          <figure className="ts-item__info">
                            <h4>{property.name}</h4>
                            <aside>
                              <i className="fa fa-map-marker mr-2"></i>
                              {property.address}
                            </aside>
                          </figure>
                          <div className="ts-item__info-badge">
                            &#8377;{property.price}
                          </div>
                          <div className="ts-description-lists">
                            <dl>
                              <dt>Area</dt>
                              <dd>
                                {property.area}
                                <sup>2</sup>
                              </dd>
                            </dl>
                            <dl>
                              <dt>Bedrooms</dt>
                              <dd>{property.bedroomCount}</dd>
                            </dl>
                            <dl>
                              <dt>Bathrooms</dt>
                              <dd>{property.bathroomCount}</dd>
                            </dl>
                          </div>
                        </div>
                        {/* <!--Card Footer--> */}
                        <a
                          className="card-footer"
                          onClick={() => this.propertyDetails(property._id)}
                        >
                          <span className="ts-btn-arrow">Detail</span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        )}
        <ShareLinkDialog
          shareLinkDialog={this.state.shareLinkDialog}
          setShareLinkDialogCancel={this.setShareLinkDialogCancel}
        />
        <Footer></Footer>
      </div>
    );
  }
}
