import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyA3quzqT7-E8mB5ok1LgT-HM-5PvwHgss0',
  authDomain: 'melzo-app.firebaseapp.com',
  databaseURL: 'https://melzo-app.firebaseio.com',
  projectId: 'melzo-app',
  storageBucket: 'melzo-app.appspot.com',
  messagingSenderId: '126598841107'
};
firebase.initializeApp(config);

export default firebase;