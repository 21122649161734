/* eslint-disable  */
import React, { Component } from "react";
import Footer from "../Footer/Footer";
import Header from "../header/Header";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import adminDetails from "../../adminDetails";
import "./about.css";
import "../style.css";
import baseUrls from "../../common/utils/base-urls";
import { errorLog } from "../../common/userlogs/userlogs";

var r = document.querySelector(":root");


export default class Aboutusdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: {},
      aboutContent: "",
      topLevel: [],
      middleLevel: [],
      bottomLevel: [],
      isDataLoaded: false,
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      let topLevel = adminDetails.teamMembers.filter(
        (team) => team.seniorityLevel == 1
      );
      let middleLevel = adminDetails.teamMembers.filter(
        (team) => team.seniorityLevel == 2
      );
      let bottomLevel = adminDetails.teamMembers.filter(
        (team) => team.seniorityLevel == 3
      );

      let data = adminDetails.about.content.replace(/\n/g,'<br/>');
      document.getElementById('aboutContentEle').innerHTML = data;
      this.setState(
        {
          adminData: adminDetails,
          aboutContent : data,
          topLevel: topLevel,
          middleLevel: middleLevel,
          bottomLevel: bottomLevel,
        },
        () => {
          this.setState({ isDataLoaded: true });
          this.myFunctionSet(adminDetails.themeColor.backgroundColor, adminDetails.themeColor.fontColor);

        }
      );
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err, "", "", "")
  }
  }
  myFunctionSet = (color, fontcolor) => {
    try{
    r.style.setProperty("--themecolor", color);
    r.style.setProperty("--fontcolor", fontcolor);
    }catch(err){
      errorLog("myFunctionSet", err, "", "", "")
    }
  };

  render() {
    return (
      <div>
        <Header></Header>
        {/* <!--PAGE TITLE ******************************************************************************************--> */}
        <section id="page-title" style={{ marginTop: "150px" }}>
          <div className="container">
            <div className="ts-title">
              <h2 className="text-dynamicColor">
                {this.state.adminData.about
                  ? this.state.adminData.about["title"]
                  : "About US "}
              </h2>
            </div>
            {/* <!--end ts-title--> */}
          </div>
          {/* <!--end container--> */}
        </section>
        {/* <!--DESCRIPTION *****************************************************************************************--> */}
        <section id="about-us-description">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <p className="mb-5" id="aboutContentEle">
                  {/* {this.state.aboutContent
                    ? this.state.aboutContent.toString()
                    : "We are happy to find best property for you..!"} */}
                </p>
                {/* <a onClick={this.aboutuspage} className="btn btn-primary float-right">Read More</a> */}
              </div>
              {/* <!--end col-md-8--> */}
              <div className="col-md-4"></div>
              {/* <!--end col-md-4--> */}
            </div>
            {/* <!--end row--> */}
            <hr className="my-5" />
          </div>
          {/* <!--end container--> */}
        </section>
        {/* <!--TEAM Member Details Seniority Level Wise=========================================================================================================--> */}
        <section id="gallery-carousel">
          <br></br>
          {this.state.topLevel && this.state.topLevel.length > 0 && (
            <h1 className="text-dynamicColor" style={{ display: "flex", justifyContent: "center" }}>
              Top Level Team
            </h1>
          )}
          <br></br>
          {/* <OwlCarousel
            autoPlay={true}
            loop
            className="ts-gallery-carousel ts-gallery-carousel__multi"
            responsiveClass={true}
            responsive={{
              0: {
                items: `${
                  this.state.topLevel ? this.state.topLevel.length : 0
                }`,
                loop: true,
              },
              600: {
                items: `${
                  this.state.topLevel ? this.state.topLevel.length : 0
                }`,
                loop: true,
              },
              700: {
                items: `${
                  this.state.topLevel ? this.state.topLevel.length : 0
                }`,
                loop: true,
              },
              1000: {
                items: `${
                  this.state.topLevel ? this.state.topLevel.length : 0
                }`,
                loop: true,
              },
            }}
          > */}
          <div className="container pb-5">
            <div className="row" style={{ justifyContent: "center" }}>
              {this.state.topLevel.map((team,index) => {
                return (
                  <div className="col-md-3" key={index}>
                    <div className="card ts-person ts-card">
                      {/* <!--Ribbon--> */}
                      {/* <div className="ts-ribbon">
                          <i className="fa fa-thumbs-up"></i>
                        </div> */}

                      {/* <!--Image--> */}
                      <div className="card-img aboutimg">
                        <img
                          src={baseUrls.images + team.image}
                          style={{
                            height: "15rem",
                            width: "100%",
                          }}
                        // style={{
                        //   backgroundImage: `url(${
                        //     baseUrls.images + team.image
                        //   })`,
                        // }}
                        ></img>
                        <div className="ts-item__info-badge">
                          <span>{team.designation}</span>
                        </div>
                      </div>

                      {/* <!--Body--> */}
                      <div className="card-body">
                        <figure className="ts-item__info">
                          <h4>{team.name}</h4>
                          <aside>
                            <i className="fa fa-map-marker mr-2"></i>
                            {team.country}
                          </aside>
                        </figure>

                        <dl>
                          <dt>Phone</dt>
                          <dd>{team.mobileNumber}</dd>
                          <dt>Email</dt>
                          <dd>
                            <a href={`mailto:${team.email}`}>{team.email}</a>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* </OwlCarousel> */}

          {/* Middle Level Team */}
          <br></br>
          {this.state.middleLevel && this.state.middleLevel.length > 0 && (
            <h1 className="text-dynamicColor" style={{ display: "flex", justifyContent: "center" }}>
              Middle Level Team
            </h1>
          )}
          <br></br>

          {/* <OwlCarousel
            autoPlay={true}
            loop
            className="ts-gallery-carousel ts-gallery-carousel__multi"
            responsiveClass={true}
            responsive={{
              0: {
                items: `${
                  this.state.middleLevel ? this.state.middleLevel.length : 0
                }`,
                loop: true,
              },
              600: {
                items: `${
                  this.state.middleLevel ? this.state.middleLevel.length : 0
                }`,
                loop: true,
              },
              700: {
                items: `${
                  this.state.middleLevel ? this.state.middleLevel.length : 0
                }`,
                loop: true,
              },
              1000: {
                items: `${
                  this.state.middleLevel ? this.state.middleLevel.length : 0
                }`,
                loop: true,
              },
            }}
          > */}
          <div className="container pb-5">
            <div className="row" style={{ justifyContent: "center" }}>
              {this.state.middleLevel.map((team,index) => {
                return (
                  <div className="col-md-3" key={index}>
                    <div className="card ts-person ts-card">
                      {/* <!--Ribbon--> */}
                      {/* <div className="ts-ribbon">
                          <i className="fa fa-thumbs-up"></i>
                        </div> */}

                      {/* <!--Image--> */}
                      <div className="card-img aboutimg">
                        <img
                          src={baseUrls.images + team.image}
                          style={{
                            height: "15rem",
                            width: "100%",
                          }}
                        // style={{
                        //   backgroundImage: `url(${
                        //     baseUrls.images + team.image
                        //   })`,
                        // }}
                        ></img>
                        <div className="ts-item__info-badge">
                          <span>{team.designation}</span>
                        </div>
                      </div>

                      {/* <!--Body--> */}
                      <div className="card-body">
                        <figure className="ts-item__info">
                          <h4>{team.name}</h4>
                          <aside>
                            <i className="fa fa-map-marker mr-2"></i>
                            {team.country}
                          </aside>
                        </figure>

                        <dl>
                          <dt>Phone</dt>
                          <dd>{team.mobileNumber}</dd>
                          <dt>Email</dt>
                          <dd>
                            <a href={`mailto:${team.email}`}>{team.email}</a>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* </OwlCarousel> */}
          {/* Bottom Level Team */}
          <br></br>
          {this.state.bottomLevel && this.state.bottomLevel.length > 0 && (
            <h1 className="text-dynamicColor" style={{ display: "flex", justifyContent: "center" }}>
              Our Work Force
            </h1>
          )}
          <br></br>
          {/* <OwlCarousel
            autoPlay={true}
            loop
            className="ts-gallery-carousel ts-gallery-carousel__multi"
            responsiveClass={true}
            responsive={{
              0: {
                items: `${
                  this.state.bottomLevel ? this.state.bottomLevel.length : 0
                }`,
                loop: true,
              },
              600: {
                items: `${
                  this.state.bottomLevel ? this.state.bottomLevel.length : 0
                }`,
                loop: true,
              },
              700: {
                items: `${
                  this.state.bottomLevel ? this.state.bottomLevel.length : 0
                }`,
                loop: true,
              },
              1000: {
                items: `${
                  this.state.bottomLevel ? this.state.bottomLevel.length : 0
                }`,
                loop: true,
              },
            }}
          > */}
          <div className="container pb-5">
            <div className="row" style={{ justifyContent: "center" }}>
              {this.state.bottomLevel.map((team,index) => {
                return (
                  <div className="col-md-3" key={index}>
                    <div className="card ts-person ts-card">
                      {/* <!--Ribbon--> */}
                      {/* <div className="ts-ribbon">
                          <i className="fa fa-thumbs-up"></i>
                        </div> */}

                      {/* <!--Image--> */}
                      <div className="card-img aboutimg">
                        <img
                          src={baseUrls.images + team.image}
                          style={{
                            height: "15rem",
                            width: "100%",
                          }}
                        // style={{
                        //   backgroundImage: `url(${
                        //     baseUrls.images + team.image
                        //   })`,
                        // }}
                        ></img>
                        <div className="ts-item__info-badge">
                          <span>{team.designation}</span>
                        </div>
                      </div>

                      {/* <!--Body--> */}
                      <div className="card-body">
                        <figure className="ts-item__info">
                          <h4>{team.name}</h4>
                          <aside>
                            <i className="fa fa-map-marker mr-2 dynamicIconColor" ></i>
                            {team.country}
                          </aside>
                        </figure>

                        <dl>
                          <dt>Phone</dt>
                          <dd>{team.mobileNumber}</dd>
                          <dt>Email</dt>
                          <dd>
                            <a href={`mailto:${team.email}`}>{team.email}</a>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* </OwlCarousel> */}
          {/* )} */}
          {/* <div className="container pb-5">
            <div className="row">
              <div className="col-md-3">
                <div className="card ts-person ts-card">
                  <a href="#" className="card-img aboutimg4">
                    <div className="ts-item__info-badge">
                      <span>28 Properties</span>
                    </div>
                  </a>
                  <div className="card-body">
                    <figure className="ts-item__info">
                      <h4>Edward Palmer</h4>
                      <aside>
                        <i className="fa fa-map-marker mr-2"></i>
                        Berlin
                      </aside>
                    </figure>
                    <dl>
                      <dt>Phone</dt>
                      <dd>+1 602-862-1673</dd>
                      <dt>Email</dt>
                      <dd>
                        <a href="#">jane.harwood@example.com</a>
                      </dd>
                    </dl>
                  </div>
                  <a onClick={() => this.Agentdetail()} className="card-footer">
                    <span className="ts-btn-arrow">Detail</span>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!--end container--> */}
        </section>
        <Footer></Footer>
      </div>
    );
  }
}
