import React, { Component } from "react";
import baseUrls from "../../common/utils/base-urls";
import "./latest.css";
import adminDetails from "../../adminDetails";
import { errorLog } from "../../common/userlogs/userlogs";

export default class Latest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      property: [],
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      this.setState(
        {
          property: adminDetails.property.sort(
            (a, b) => b.dateTime - a.dateTime
          ),
        },
        () =>
          this.setState({
            isLoaded: true,
          })
      );
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err, "", "", "")
  }
}

  propertyDetails = (propertyId) => {
    try{
    window.location.href =
      "/" + localStorage.getItem("webName") + "/" + propertyId;
    }catch(err){
      errorLog("propertyDetails" , err, "", "", "")
    }
  };


  render() {
    return (
      <div>
        {/* <!--LATEST LISTINGS
        =============================================================================================================--> */}
        <section id="latest-listings" className="ts-block paddingtopsection">
          <div className="container">
            {/* <!--Title--> */}
            <div className="ts-title">
              <h2 className="text-dynamicColor">Latest Listings</h2>
            </div>

            {/* <!--Row--> */}
            {this.state.property.length > 0 && (
              <div className="row">
                {/* <!--Item--> */}
                {this.state.property.map((propertyData, index) => {
                  if (index < 8) {
                    return (
                      <div className="col-sm-6 col-lg-3">
                        <div className="card ts-item ts-card">
                          {/* <!--Ribbon--> */}
                          <div className="ts-ribbon-corner">
                            <span>New</span>
                          </div>

                          {/* <!--Card Image--> */}
                          <a
                            onClick={() =>
                              this.propertyDetails(propertyData._id)
                            }
                            className="card-img ts-item__image listingimg"
                            style={{
                              backgroundImage: `url(${
                                baseUrls.images + propertyData.images[0]
                              })`,
                            }}
                          >
                            <figure className="ts-item__info">
                              <h4>{propertyData.name}</h4>
                              <aside>
                                <i className="fa fa-map-marker mr-2 dynamicIconColor"></i>
                                {propertyData.address}
                              </aside>
                            </figure>
                            <div className="ts-item__info-badge">
                              &#8377;{propertyData.price}
                            </div>
                          </a>

                          {/* <!--Card Body--> */}
                          <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                              <dl>
                                <dt>Area</dt>
                                <dd>
                                  {propertyData.area + "m"}
                                  <sup>2</sup>
                                </dd>
                              </dl>
                              <dl>
                                <dt>Bedrooms</dt>
                                <dd>{propertyData.bedroomCount}</dd>
                              </dl>
                              <dl>
                                <dt>Bathrooms</dt>
                                <dd>{propertyData.bathroomCount}</dd>
                              </dl>
                            </div>
                          </div>

                          {/* <!--Card Footer--> */}
                          <a
                            onClick={() =>
                              this.propertyDetails(propertyData._id)
                            }
                            className="card-footer"
                          >
                            <span className="ts-btn-arrow dynamicIconColor">View More Details</span>
                          </a>
                        </div>
                      </div>
                    );
                  }
                })}
                {/* <!--end Item col-md-4--> */}
              </div>
            )}
            {/* <!--end row--> */}
          </div>
          {/* <!--end container--> */}
        </section>
      </div>
    );
  }
}
