const adminDetails = {
  name: "",
  webName: "",
  property: [],
  about: {},
  email: "",
  mobileNumber: "",
  logo: "noImage",
  bannerData: {},
  allData: [],
  calendly: "",
  whatsappNumber: "",
  socialMedia: {},
  teamMembers: [],
  themeColor: { backgroundColor: "#000000", fontColor: "#ffffff" },
  footer: "",
  getInTouchContent: "",
  address: {},
  privacyPolicyUrl: "",
  parentUid: "",
  filterProperty: [],
};

export default adminDetails;
