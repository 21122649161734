/*eslint-disable*/ 
import React from "react";
import "./warning.css";
export default function Warning() {
  return (
      <div className="warning__container">
    <div className="warningwrapper">
      <article className="alert warning">
        <div className="alert__warningwrapper">
          <div className="alert__header">
            <h3>
              <span>
                <i className="fa fa-exclamation-triangle" />
              </span>
              <span style={{textAlign:'center'}}>Warning!</span>
            </h3>
          </div>
          <div className="alert__body">
            <p>
            Services are temporary stopped due to low credit Please Activate Your Subscription.
            </p>
            <p>
            Due To Following Reasons : </p><p>
            1. Lower Wallet Credit</p><p>
            2. Inactive Subscription Or Cancelled Subscription</p><p>
            3. Data Usage Exceeded</p>
            <p>
            Contact Administrator  &gt;&gt; contact@shilpmis.com</p>
          </div>
        </div>
      </article>
    </div>
    </div>
  );
}
