import axios from "axios";
import React, { Component } from "react";
import adminDetails from "../../adminDetails";
import userDetail from "../../common/userlogs/userDetail";
import { errorLog } from "../../common/userlogs/userlogs";
import baseUrls from "../base-urls";
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: {},

      inqName: "",
      inqEmail: "",
      inqSubject: "",
      inqMessage: "",
    };
  }

  componentDidMount() {
    try{
     
    setTimeout(() => {
      this.setState({ adminData: adminDetails });
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err,"", "", "")
  
  }
  }
  //Set State using this event
  onChangeInqForm = (e) => {
    try{
    this.setState({
      [e.target.name]: e.target.value
    })
  }catch(err){
    errorLog("onChangeInqForm", err, "", "", "")
  }
}

  //service Call to Save data
  onSubmitForm = () => {
try{
    if (this.state.inqName == "") {
      alert("Enter Name")
      return;
    }
    if (this.state.inqEmail == "") {
      alert("Enter Email")
      return;
    }
    if (this.state.inqSubject == "") {
      alert("Enter Subject")
      return;
    }
    if (this.state.inqMessage == "") {
      alert("Enter Message")
      return;
    }

    let tmpObj = {
      name: this.state.inqName,
      email: this.state.inqEmail,
      expo: localStorage.getItem('webName'),
      country: "",
      mobileNumber: userDetail.mobileNumber,
      subject: this.state.inqSubject,
      message: this.state.inqEmail,
      formName: 'contact',
      productName: 'Melzo Aakar',
      projectId: "",
      uid: userDetail.id,
    }
    axios.post(baseUrls.serverUrl + `userform`, tmpObj).then(res => {
      this.setState({
        inqName: "",
        inqEmail: "",
        inqSubject: "",
        inqMessage: "",
      })
    }).catch(err => {
      console.error("err :: ", err);
    })
  }catch(err){
    errorLog("onSubmitForm", err,"", "", "")
  }
}


  render() {
    return (
      <div id="contact" className="paddingtopsection">
        {/* <!--MAP
            =========================================================================================================--> */}
        {/* <section id="map-address">

                    <div className="container mb-5">
                        <div className="ts-contact-map ts-map ts-shadow__sm position-relative">

                            <address className="position-absolute ts-bottom__0 ts-left__0 text-white m-3 p-4 ts-z-index__2" data-bg-color="rgba(0,0,0,.8)" style={{background:'rgba(0,0,0,.8)'}}>
                                <strong>Real Estate Agency</strong>
                                <br />
                                    2501 Rosemont Avenue
                        <br />
                                        Orlando, FL 32801
                    </address>

                            <div id="ts-map-simple" className="h-100 ts-z-index__1"
                                data-ts-map-leaflet-provider="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png"
                                data-ts-map-zoom="14"
                                data-ts-map-center-latitude="40.702411"
                                data-ts-map-center-longitude="-73.556842"
                                data-ts-map-scroll-wheel="1"
                                data-ts-map-controls="0"></div>
                        </div>
                    </div>

                </section> */}
        {/* <!--CONTACT INFO & FORM
            =========================================================================================================--> */}
        <section id="contact-form">
          <div className="container">
            <div className="row">
              {/* <!--CONTACTS (LEFT SIDE)
                        =============================================================================================--> */}
              <div className="col-md-4">
                {/* <!--Title--> */}
                <h3 className="text-dynamicColor">Get In Touch</h3>

                <p>{this.state.adminData.getInTouchContent ? this.state.adminData.getInTouchContent : "We are happy to find best property for you..!"}</p>

                {/* <!--Phone--> */}
                <figure className="ts-center__vertical">
                  <i className="fa fa-phone ts-opacity__50 mr-3 mb-0 h4 font-weight-bold dynamicIconColor"></i>
                  <dl className="mb-0">
                    <dt className="text-dynamicColor">Phone</dt>
                    <dd className="ts-opacity__50">
                      <a href={`tel:${this.state.adminData.mobileNumber}`}>
                        {"+91 " +this.state.adminData.mobileNumber}
                      </a>
                    </dd>
                  </dl>
                </figure>

                {/* <!--Email--> */}
                <figure className="ts-center__vertical">
                  <i className="fa fa-envelope ts-opacity__50 mr-3 mb-0 h4 font-weight-bold dynamicIconColor"></i>
                  <dl className="mb-0">
                    <dt className="text-dynamicColor">Email</dt>
                    <dd className="ts-opacity__50">
                      <a href={`mailto:${this.state.adminData.email}`}>
                        {this.state.adminData.email}
                      </a>
                    </dd>
                  </dl>
                </figure>

                {/* <!--Skype--> */}
                {/* <figure className="ts-center__vertical">
                  <i className="fab fa-skype ts-opacity__50 mr-3 mb-0 h4 font-weight-bold"></i>
                  <dl className="mb-0">
                    <dt>Skype</dt>
                    <dd className="ts-opacity__50">
                      <a href="#">real.estate.agency</a>
                    </dd>
                  </dl>
                </figure> */}
              </div>
              {/* <!--end col-md-4--> */}

              {/* <!--FORM (RIGHT SIDE)
                        =============================================================================================--> */}
              <div className="col-md-8">
                {/* <!--Title--> */}
                <h3 className="text-dynamicColor">Contact Form</h3>

                {/* <!--Form--> */}

                {/* <!--Row--> */}
                <div className="row">
                  {/* <!--Name--> */}
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label for="form-contact-name">Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="form-contact-name"
                        name='inqName'
                        value={this.state.inqName}
                        onChange={this.onChangeInqForm}
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </div>

                  {/* <!--Email--> */}
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label for="form-contact-email">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="form-contact-email"
                        name='inqEmail'
                        value={this.state.inqEmail}
                        onChange={this.onChangeInqForm}
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* <!--end /row --> */}

                {/* <!--Subject--> */}
                <div className="form-group">
                  <label for="form-contact-email">Subject*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="form-contact-subject"
                    name='inqSubject'
                    value={this.state.inqSubject}
                    onChange={this.onChangeInqForm}
                    placeholder="Subject"
                    required
                  />
                </div>

                {/* <!--Message--> */}
                <div className="form-group">
                  <label for="form-contact-message">Message *</label>
                  <textarea
                    className="form-control"
                    id="form-contact-message"
                    rows="5"
                    name='inqMessage'
                    value={this.state.inqMessage}
                    onChange={this.onChangeInqForm}
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>

                {/* <!--Submit button--> */}
                <div className="form-group clearfix">
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    id="form-contact-submit"
                    onClick={() => this.onSubmitForm()}
                  >
                    Send a Message
                  </button>
                </div>

                <div className="form-contact-status"></div>

                {/* <!--end form-contact --> */}
              </div>
              {/* <!--end col-md-8--> */}
            </div>
            {/* <!--end row--> */}
          </div>
          {/* <!--end container--> */}
        </section>

        {/* <!--end #ts-main--> */}
      </div>
    );
  }
}
