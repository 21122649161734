import moment from "moment";
import React, { Component } from "react";
import { analyticsFunction, errorLog } from "../../common/userlogs/userlogs";
import baseUrls from "../../common/utils/base-urls";
import "./properties.css";
import { withRouter } from "react-router-dom";
import adminDetails from "../../adminDetails";

class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 3,
      isLoaded: false,
      property: [],
    };
  }

  componentDidMount() {
    try{
    setTimeout(() => {
      this.setState(
        {
          property: adminDetails.property,
        },
        () =>
          this.setState({
            isLoaded: true,
          })
      );
    }, 1000);
  }catch(err){
    errorLog("componentDidMount", err, "", "", "")
  }
}

  propertyDetails = (propertyId) => {
    try{
    analyticsFunction("Property Click", propertyId);
    window.location.href =
      "/" + localStorage.getItem("webName") + "/" + propertyId;
    }catch(err){
      errorLog("propertyDetails", err, "", "", "")
    }
  };

  render() {
    return (
      <div>
        {/* <!-- FEATURED PROPERTIES
        =============================================================================================================--> */}
        <section
          id="featured-properties"
          className="ts-block  paddingtopsection"
        >
          <div className="container">
            {/* <!--Title--> */}
            <div className="ts-title text-center">
              <h2 className="text-dynamicColor" style={{ textAlign: "left" }}>
                Properties
              </h2>
            </div>
            {this.state.property.length > 0 && (
              <div className="row">
                {/* <!--Item--> */}

                {/* <div className="col-sm-6 col-lg-4">
                <div className="card ts-item ts-card ts-item__lg">
                  <div className="ts-ribbon">
                    <i className="fa fa-thumbs-up"></i>
                  </div>

                  <a
                    onClick={this.Property}
                    className="card-img ts-item__image propertiesimg1"
                  >
                    <div className="ts-item__info-badge">$350,000</div>
                    <figure className="ts-item__info">
                      <h4>Big Luxury Apartment</h4>
                      <aside>
                        <i className="fa fa-map-marker mr-2"></i>
                        1350 Arbutus Drive
                      </aside>
                    </figure>
                  </a>

                  <div className="card-body">
                    <div className="ts-description-lists">
                      <dl>
                        <dt>Area</dt>
                        <dd>
                          325m<sup>2</sup>
                        </dd>
                      </dl>
                      <dl>
                        <dt>Bedrooms</dt>
                        <dd>2</dd>
                      </dl>
                      <dl>
                        <dt>Bathrooms</dt>
                        <dd>1</dd>
                      </dl>
                    </div>
                  </div>

                  <a onClick={this.Property} className="card-footer">
                    <span className="ts-btn-arrow">Detail</span>
                  </a>
                </div>
              </div> */}
                {/* <!--end Item col-md-4--> */}

                {/* <!--Item--> */}
                {this.state.property.map((propertyData, index) => {
                  if (index < this.state.count) {
                    return (
                      <div
                      key={index}
                        className="col-sm-6 col-lg-4"
                        onClick={() => this.propertyDetails(propertyData._id)}
                      >
                        <div className="card ts-item ts-card ts-item__lg">
                          {/* <!--Ribbon--> */}
                          <div className="ts-ribbon-corner">
                            <span>
                              {moment(propertyData.dateTime).fromNow()}
                            </span>
                          </div>

                          {/* <!--Card Image--> */}
                          <a
                            onClick={() =>
                              this.propertyDetails(propertyData._id)
                            }
                            className="card-img ts-item__image propertiesimg propertiesimg"
                            style={{
                              backgroundImage: `url(${
                                baseUrls.images + propertyData.images[0]
                              })`,
                            }}
                          >
                            <figure className="ts-item__info">
                              <h4>{propertyData.name}</h4>
                              <aside>
                                <i className="fa fa-map-marker mr-2 dynamicIconColor"></i>
                                {propertyData.address}
                              </aside>
                            </figure>
                            <div className="ts-item__info-badge">
                              &#8377;{propertyData.price}{" "}
                            </div>
                          </a>

                          {/* <!--Card Body--> */}
                          <div className="card-body ts-item__body">
                            <div className="ts-description-lists">
                              <dl>
                                <dt>Area</dt>
                                <dd>
                                  {propertyData.area + "m"}
                                  <sup>2</sup>
                                </dd>
                              </dl>
                              <dl>
                                <dt>Bedrooms</dt>
                                <dd>{propertyData.bedroomCount}</dd>
                              </dl>
                              <dl>
                                <dt>Bathrooms</dt>
                                <dd>{propertyData.bathroomCount}</dd>
                              </dl>
                            </div>
                          </div>

                          {/* <!--Card Footer--> */}
                          <a
                            className="card-footer"
                            onClick={() =>
                              this.propertyDetails(propertyData._id)
                            }
                          >
                            <span className="ts-btn-arrow dynamicIconColor">View More Details</span>
                          </a>
                        </div>
                        {/* <!--end ts-item ts-card--> */}
                      </div>
                    );
                  }
                })}
              </div>
            )}
            {/* <!--end row--> */}

            {/* <!--All properties button--> */}
            <div className="text-center mt-3">
              <a
                className="btn btn-outline-dark ts-btn-border-muted"
                onClick={() => {
                  this.props.history.push(
                    `/${localStorage.getItem("webName")}`
                  );
                  setTimeout(() => {
                    let productEl = document.getElementById(
                      "featured-properties"
                    );
                    if (productEl) {
                      productEl.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }, 500);
                  this.setState({
                    count:
                      this.state.count == 3
                        ? this.state.property && this.state.property.length
                        : 3,
                  });
                }}
              >
                {this.state.count == 3
                  ? "Show All Properties"
                  : "Show Less Properties"}
              </a>
            </div>
          </div>
          {/* <!--end container--> */}
        </section>
      </div>
    );
  }
}

export default withRouter(Properties);
