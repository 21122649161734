/* elsint-disable */
import React, { Component } from "react";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import firebaseStoreAakar from "../../firebase-vr-expo";
import $ from "jquery";
import CryptoJS from "crypto-js";
import { currentServerDate } from "../serverTime/serverTime";
import axios from "axios";
import baseUrls from "../base-urls";
import adminDetails from "../../adminDetails";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import userDetail from "../../common/userlogs/userDetail";
import { analyticsFunction, errorLog } from "../../common/userlogs/userlogs";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      userData: [],
      countryCode: {},
    };
  }
  componentDidMount() {
    try{
    $(".ts-password-toggle").on("click", function () {
      var $parent = $(this).closest(".ts-has-password-toggle");
      var $this = $(this);
      var $password = $parent.find("input");
      if ($password.attr("type") === "password") {
        $password.attr("type", "text");
        $this.find("i").removeClass("fa-eye-slash").addClass("fa-eye");
      } else {
        $password.attr("type", "password");
        $this.find("i").removeClass("fa-eye").addClass("fa-eye-slash");
      }
    });
    let finalPropertydata = {
      webName: this.props.webName,
    };
    axios
      .post(baseUrls.serverUrl + "visitor/getVisitors", finalPropertydata)
      .then((res) => {
        this.setState({ userData: res.data });
      })
      .catch((err) => {
        console.error("Err => ", err);
      });
  }catch(err){
    errorLog("componentDidMount" , err, "", "", "")
  }
}

  handleInputChange = (e) => {
    try{
    this.setState({
      [e.target.name]: e.target.value,
    });
  }catch(err){
    errorLog("handleInputChange" , err, "", "", "")
  }
  };

  registerUser = () => {
    try{
    let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (this.state.userName == "") {
      alert("Please Enter User Name");
      return;
    }
    if (this.state.email == "") {
      alert("Please Enter Email Id");
      return;
    }
    if (this.state.email && !patternEmail.test(this.state.email)) {
      alert("Please Enter Valid Email ID!");
      return;
    }
    if (this.state.phone == "91" || this.state.phone == "") {
      alert("Please Enter Mobile Number");
      return;
    }
    if (this.state.password == "") {
      alert("Please Enter Password");
      return;
    }
    if (this.state.confirmPassword == "") {
      alert("Please Enter Confirm Password");
      return;
    }
    if (this.state.password != this.state.confirmPassword) {
      alert("Password Not Match");
      return;
    }

    this.state.userData.map((obj) => {
      if (obj.email == this.state.email) {
        alert("Email ID Already In Use!");
        return;
      }
    });

    // let encryptPassword = CryptoJS.AES.encrypt(
    //     JSON.stringify(this.state.password),
    //     "aakar"
    // ).toString();

    let finalPropertydata = {
      webName: this.props.webName,
      name: this.state.userName,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      userRole: "visitor",
      productName: "Melzo Aakar",
      mobileNumber: this.state.phone,
      // countryCode: this.state.countryCode,
    };

    axios
      .post(baseUrls.serverUrl + `visitor/register`, finalPropertydata)
      .then((res) => {
        userDetail.webName = res.data.webName;
        userDetail.name = res.data.name;
        userDetail.email = res.data.email;
        userDetail.password = res.data.password;
        userDetail.userRole = res.data.userRole;
        userDetail.mobileNumber = res.data.mobileNumber;
        userDetail.id = res.data._id;

        analyticsFunction("User Register", "");
        localStorage.setItem("webName", this.props.webName);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userId", res.data._id);
        this.props.loginCallBack();
      })
      .catch((err) => {
        console.error("Err=>", err);
      });
    }catch(err){
      errorLog("registerUser" , err, "", "", "")
    }
  };

  loginUser = () => {
    let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (this.state.email == "") {
      alert("Please Enter Email Id");
      return;
    }
    if (this.state.email && !patternEmail.test(this.state.email)) {
      alert("Please Enter Valid Email ID!");
      return;
    }
    if (this.state.password == "") {
      alert("Please Enter Password");
      return;
    }

    let finalPropertydata = {
      webName: this.props.webName,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
    };

    axios
      .post(baseUrls.serverUrl + `visitor/login`, finalPropertydata)
      .then((res) => {
        if (res.data.status == 401) {
          alert(res.data.res);
          return;
        }
        if (res.data.status == 400) {
          alert(res.data.res);
          return;
        }

        userDetail.webName = res.data.visitorData.webName;
        userDetail.name = res.data.visitorData.name;
        userDetail.email = res.data.visitorData.email;
        userDetail.password = res.data.visitorData.password;
        userDetail.userRole = res.data.visitorData.userRole;
        userDetail.mobileNumber = res.data.visitorData.mobileNumber;
        userDetail.id = res.data.visitorData._id;

        localStorage.setItem("webName", this.props.webName);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userId", res.data.visitorData._id);

        analyticsFunction("User Login", "");

        this.props.loginCallBack();
      })
      .catch((err) => {
        console.error("Err ==> ", err, "", "", "");
      });
  };

  handleLoginEmailKeyPress = (e) => {
    try{
    if (e.keyCode === 13) {
      document.getElementById("loginPassword").focus();
    }
  }catch(err){
    errorLog("handleLoginEmailKeyPress" , err, "", "", "")
  }
  };

  handleLoginPasswordKeyPress = (e) => {
    try{
    if (e.keyCode === 13) {
      document.getElementById("loginButton").click();
    }
  }catch(err){
    errorLog("handleLoginEmailKeyPress" , err , "", "", "")
  }
  };

  handleRegistrationKeyPress = (type, e) => {
    try{
    if (e.keyCode === 13) {
      let docId = type;
      document.getElementById(docId).focus();
    }
  }catch(err){
    errorLog("handleRegistrationKeyPress", err, "", "", "")
  }
  };

  handleRegisterPasswordKeyPress = (e) => {
    try{
    if (e.keyCode === 13) {
      document.getElementById("registerButton").click();
    }
  }catch(err){
    errorLog("handleRegisterPasswordKeyPress", err, "", "", "")
  }
  };

  render() {
    return (
      <div style={{ background: "#f6f6f6" }}>
        <Header></Header>
        {/* <!--PAGE TITLE
            =========================================================================================================--> */}
        <section id="page-title" style={{ paddingTop: "150px" }}>
          <div className="container">
            <div className="ts-title">
              <h1>Login</h1>
            </div>
          </div>
        </section>
        {/* <!--LOGIN / REGISTER SECTION
            =========================================================================================================--> */}
        <section id="login-register">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                {/* <!--LOGIN / REGISTER TABS
                            =========================================================================================--> */}
                <ul
                  className="nav nav-tabs"
                  id="login-register-tabs"
                  role="tablist"
                >
                  {/* <!--Login tab--> */}
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="login-tab"
                      data-toggle="tab"
                      href="#login"
                      role="tab"
                      aria-controls="login"
                      aria-selected="true"
                    >
                      <h3>Login</h3>
                    </a>
                  </li>

                  {/* <!--Register tab--> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="register-tab"
                      data-toggle="tab"
                      href="#register"
                      role="tab"
                      aria-controls="register"
                      aria-selected="false"
                    >
                      <h3>Register</h3>
                    </a>
                  </li>
                </ul>

                {/* <!--TAB CONTENT
                            =========================================================================================--> */}
                <div className="tab-content">
                  {/* <!--LOGIN TAB
                                =====================================================================================--> */}
                  <div
                    className="tab-pane active"
                    id="login"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                  >
                    {/* <!--Login form--> */}
                    {/* <form className="ts-form" id="form-login"> */}

                    {/* <!--Email--> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="login-email"
                        placeholder="Email"
                        name="email"
                        onKeyUp={this.handleLoginEmailKeyPress}
                        value={this.state.email}
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      />
                    </div>

                    {/* <!--Password--> */}
                    <div className="input-group ts-has-password-toggle">
                      <input
                        type="password"
                        id="loginPassword"
                        className="form-control border-right-0"
                        placeholder="Password"
                        name="password"
                        onKeyUp={this.handleLoginPasswordKeyPress}
                        value={this.state.password}
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      />
                      <div className="input-group-append">
                        <a className="input-group-text bg-white border-left-0 ts-password-toggle">
                          <i className="fa fa-eye-slash"></i>
                        </a>
                      </div>
                    </div>

                    {/* <!--Checkbox and Submit button--> */}
                    <div className="ts-center__vertical justify-content-between">
                      {/* <!--Remember checkbox--> */}
                      {/* <div className="custom-control custom-checkbox mb-0">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="login-check"
                        />
                        <label
                          className="custom-control-label"
                          for="login-check"
                        >
                          Remember Me
                        </label>
                      </div> */}

                      {/* <!--Submit button--> */}
                      <button
                        type="submit"
                        id="loginButton"
                        className="btn btn-primary"
                        onClick={() => this.loginUser()}
                      >
                        Login
                      </button>
                    </div>

                    <hr />

                    {/* <!--Forgot password link--> */}
                    {/* <a href="#" className="ts-text-small">
                      <i className="fa fa-sync-alt ts-text-color-primary mr-2"></i>
                      <span className="ts-text-color-light">
                        I have forgot my password
                      </span>
                    </a> */}

                    {/* </form> */}
                  </div>
                  {/* <!--end #login.tab-pane--> */}

                  {/* <!--REGISTER TAB
                                =====================================================================================--> */}
                  <div
                    className="tab-pane"
                    id="register"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                  >
                    {/* <!--Register tab--> */}
                    {/* <form className="ts-form" id="form-register"> */}

                    {/* <!--Name--> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="register-name"
                        placeholder="Username"
                        name="userName"
                        onKeyUp={(e) =>
                          this.handleRegistrationKeyPress("register-email", e)
                        }
                        value={this.state.userName}
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      />
                    </div>

                    {/* <!--Email--> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="register-email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => this.handleInputChange(e)}
                        // onKeyUp={(e) =>
                        //   this.handleRegistrationKeyPress("register-phone", e)
                        // }
                        required
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{ width: "100% !important" }}
                    >
                      <PhoneInput
                        inputProps={{Name : "register-phone"}}
                        style={{ width: "100% !important" }}
                        preferredCountries={["in"]}
                        enableTerritories
                        enableAreaCodes
                        value={this.state.phone}
                        onChange={(phone, country) => {
                          this.setState({ phone: phone, countryCode: country });
                        }}
                        onKeyDown={(e) =>
                          this.handleRegistrationKeyPress(
                            "register-password",
                            e
                          )
                        }
                        enableSearch
                        country={"in"}
                        inputClass=""
                      />

                      {/* <input
                        type="number"
                        className="form-control"
                        id="register-number"
                        placeholder="ContactNumber"
                        name="number"
                        value={this.state.phone}
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      /> */}
                    </div>

                    {/* <!--Password--> */}
                    <div className="input-group ts-has-password-toggle">
                      <input
                        id="register-password"
                        type="password"
                        className="form-control border-right-0"
                        placeholder="Password"
                        name="password"
                        value={this.state.password}
                        onKeyDown={(e) =>
                          this.handleRegistrationKeyPress("confirm-password", e)
                        }
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      />
                      <div className="input-group-append">
                        <a className="input-group-text bg-white border-left-0 ts-password-toggle">
                          <i className="fa fa-eye-slash"></i>
                        </a>
                      </div>
                    </div>

                    {/* <!--Repeat Password--> */}
                    <div className="input-group ts-has-password-toggle">
                      <input
                        id="confirm-password"
                        type="password"
                        className="form-control border-right-0"
                        placeholder="Repeat Password"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onKeyDown={(e) =>
                          this.handleRegistrationKeyPress("register-check", e)
                        }
                        onChange={(e) => this.handleInputChange(e)}
                        required
                      />
                      <div className="input-group-append">
                        <a className="input-group-text bg-white border-left-0 ts-password-toggle">
                          <i className="fa fa-eye-slash"></i>
                        </a>
                      </div>
                    </div>

                    {/* <!--Checkbox--> */}
                    <div className="custom-control custom-checkbox mb-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="register-check"
                        onKeyDown={this.handleRegisterPasswordKeyPress}
                        required
                      />
                      <label
                        className="custom-control-label"
                        for="register-check"
                      >
                        I Agree With{" "}
                        <a
                          href={
                            baseUrls.s3BucketDocuments +
                            adminDetails.privacyPolicyUrl
                          }
                          target="_blank"
                          className="btn-link"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>

                    {/* <!--Submit button--> */}
                    <button
                      type="submit"
                      id="registerButton"
                      className="btn btn-primary"
                      onClick={() => this.registerUser()}
                    >
                      Register
                    </button>

                    {/* </form> */}
                  </div>
                  {/* <!--end #register.tab-pane--> */}
                </div>
                {/* <!--end tab-content--> */}
              </div>
              {/* <!--end offset-4.col-md-4--> */}
            </div>
            {/* <!--end row--> */}
          </div>
          {/* <!--end container--> */}
        </section>
        <Footer></Footer>
      </div>
    );
  }
}
