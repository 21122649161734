import moment from "moment";
import React, { Component } from "react";
import { analyticsFunction, errorLog } from "../../common/userlogs/userlogs";
import baseUrls from "../../common/utils/base-urls";
import "./properties.css";
// import { withRouter } from "react-router-dom";
import adminDetails from "../../adminDetails";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import "../style.css";
import { withRouter } from "react-router-dom";

var r = document.querySelector(":root");

class SearchProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 3,
      isLoaded: false,
      property: [],
    };
  }

  componentDidMount() {
    try {
      setTimeout(() => {
        this.setState(
          {
            property: adminDetails.filterProperty,
          },
          () => {
            console.log(
              "data in search",
              this.state.property,
              adminDetails.filterProperty
            );
            this.setState({
              isLoaded: true,
            });
            this.myFunctionSet(
              adminDetails.themeColor.backgroundColor,
              adminDetails.themeColor.fontColor
            );
          }
        );
      }, 1000);
    } catch (err) {
      errorLog("componentDidMount", err, "", "", "");
    }
  }

  myFunctionSet = (color, fontcolor) => {
    try {
      r.style.setProperty("--themecolor", color);
      r.style.setProperty("--fontcolor", fontcolor);
    } catch (err) {
      errorLog("myFunctionSet", err, "", "", "");
    }
  };

  propertyDetails = (propertyId) => {
    try {
      analyticsFunction("Property Click", propertyId);
      window.location.href =
        "/" + localStorage.getItem("webName") + "/" + propertyId;
    } catch (err) {
      errorLog("propertyDetails", err, "", "", "");
    }
  };

  render() {
    return (
      <div>
        <Header></Header>
        {/* <!-- FEATURED PROPERTIES
        =============================================================================================================--> */}
        <section
          id="featured-properties"
          className="ts-block  paddingtopsection"
        >
          <div className="container">
            {/* <!--Title--> */}
            <div className="ts-title text-center">
              <h2 className="text-dynamicColor" style={{ textAlign: "left" }}>
                Filtered Properties
              </h2>
            </div>
            {this.state.property.length > 0 ? (
              <div className="row">
                {this.state.property.map((propertyData, index) => {
                  return (
                    <div
                      className="col-sm-6 col-lg-4"
                      key={index}
                      onClick={() => this.propertyDetails(propertyData._id)}
                    >
                      <div className="card ts-item ts-card ts-item__lg">
                        {/* <!--Ribbon--> */}
                        <div className="ts-ribbon-corner">
                          <span>{moment(propertyData.dateTime).fromNow()}</span>
                        </div>

                        {/* <!--Card Image--> */}
                        <a
                          onClick={() => this.propertyDetails(propertyData._id)}
                          className="card-img ts-item__image propertiesimg propertiesimg"
                          style={{
                            backgroundImage: `url(${
                              baseUrls.images + propertyData.images[0]
                            })`,
                          }}
                        >
                          <figure className="ts-item__info">
                            <h4>{propertyData.name}</h4>
                            <aside>
                              <i className="fa fa-map-marker mr-2 dynamicIconColor"></i>
                              {propertyData.address}
                            </aside>
                          </figure>
                          <div className="ts-item__info-badge">
                            &#8377;{propertyData.price}{" "}
                          </div>
                        </a>

                        {/* <!--Card Body--> */}
                        <div className="card-body ts-item__body">
                          <div className="ts-description-lists">
                            <dl>
                              <dt>Area</dt>
                              <dd>
                                {propertyData.area + "m"}
                                <sup>2</sup>
                              </dd>
                            </dl>
                            <dl>
                              <dt>Bedrooms</dt>
                              <dd>{propertyData.bedroomCount}</dd>
                            </dl>
                            <dl>
                              <dt>Bathrooms</dt>
                              <dd>{propertyData.bathroomCount}</dd>
                            </dl>
                          </div>
                        </div>

                        {/* <!--Card Footer--> */}
                        <a
                          className="card-footer"
                          onClick={() => this.propertyDetails(propertyData._id)}
                        >
                          <span className="ts-btn-arrow dynamicIconColor">
                            Detail
                          </span>
                        </a>
                      </div>
                      {/* <!--end ts-item ts-card--> */}
                    </div>
                  );
                })}
              </div>
            ) : (
              <h1 style={{display : "flex", justifyContent : "center"}}>No Property available in your filter range</h1>
            )}

            {/* <!--end row--> */}
          </div>
          {/* <!--end container--> */}
        </section>
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(SearchProperties);
