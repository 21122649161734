// TODO: move configs to melzo/src
export default {
  images: 'https://d2mjqh1irji0ds.cloudfront.net/',
  videos: 'https://d1xjufsjr32fce.cloudfront.net/',
  dash: 'https://d2ehf0c0g5fbv5.cloudfront.net/',
  sounds: 'https://d1yggmyji954u4.cloudfront.net/',
  document: 'https://d3eb5op7vv146t.cloudfront.net/',
  s3BucketImages: "https://pptvr-images.s3.ap-south-1.amazonaws.com/",
  s3BucketSounds: "https://pptvr-sounds.s3.ap-south-1.amazonaws.com/",
  s3BucketVideos: "https://pptvr-videos.s3.ap-south-1.amazonaws.com/",
  s3BucketDocuments: "https://pptvr-documents.s3.ap-south-1.amazonaws.com/",
  s3BucketModels: "https://pptvr-3d-models.s3.ap-south-1.amazonaws.com/",
  baseUrlModels: "https://d37gt58m4pkn19.cloudfront.net/",
  baseUrlDocuments: "https://d3eb5op7vv146t.cloudfront.net/",
  serverUrl:"https://ptpvr.com/"
};
